import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/List';
import SaveIcon from '@material-ui/icons/Save';
import {
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid, Typography, Checkbox
} from '@material-ui/core';
import {
  useDataProvider
}
  from 'react-admin';
const ref = React.createRef();

function TenderBasicList(props) {
  const { projectId, RevId, Prev_revId } = props;
  const dataProvider = useDataProvider();
  const [rows, setRows] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [isClicked, setIsCliked] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    dataProvider.getvariations('tenderbasicorginal', { filter: { project: projectId, revId: RevId, Prev_revId: Prev_revId }, pagination: {}, sort: { field: 'id', order: 'asc' }, }).then(({ data }) => {
      if (data && data.tenderbasic.length > 0) {
        if (data.tenderbasic[0].revId !== RevId) {
          const new_obj = {
            Prev_revId: Prev_revId,
            revId: RevId,
            projectId: projectId,
          }
          dataProvider.newcreate1('tenderbasicorginal-group-insert', { data: new_obj }).then(({ data }) => {
            setLoading(false);
            setRows(data)
          })
            .catch(err => {
              setLoading(false);
            });
          // var updatearray = []
          // data.tenderbasic.map((item) => {
          //   var newupdate = { name: item.name, project: projectId, revId: RevId, refId: item.refId }
          //   updatearray.push(newupdate)
          // })
          // if (updatearray && updatearray.length > 0) {
          //   dataProvider.createCategory({ updates: updatearray, projectId: projectId, revId: RevId, Prev_revId: Prev_revId }, 'tenderbasicorginal-array').then(({ data }) => {
          //     setRows(data)
          //     setLoading(false);
          //   }).catch(error => {

          //   });
          // }
        }
        else {
          setRows(data)
          setLoading(false);
        }
      } else {
        setRows(data)
        setLoading(false);
      }
    })
      .catch(error => {
        setLoading(false);
      });
  }, [isClicked]);

  const SingleCategoryViewNew = ({ item, basicList }) => {

    const savefunction = (change, estimateIndex, field_name) => {
      var new_object = {
        created_at: basicList[estimateIndex]?.created_at,
        dateAdded: basicList[estimateIndex]?.dateAdded,
        id: basicList[estimateIndex]?._id,
        name: change,
        project: projectId,
        refId: basicList[estimateIndex]?.refId,
        revId: basicList[estimateIndex]?.revId,
        _id: basicList[estimateIndex]?._id,
      }
      dataProvider.updateEstimate('tenderbasicorginal', new_object).then(({ data }) => {
        setRows(data)
      })
    }

    const Estimate_Delete = (subcat, event) => {
      dataProvider.deleteEstimate('tenderbasicorginal', { id: subcat?._id, projectId: projectId, Prev_revId: Prev_revId, revId: RevId }).then(({ data }) => {
        setRows(data)
      })
        .catch(error => {
          setLoading(false);
        });
    }
    const Estimate_Save = (subcat, event) => {
      const new_obj =
      {
        name: subcat.name,
        project: projectId,
        revId: RevId,
        refId: subcat._id,
      }
      dataProvider.newcreate1('tenderbasicorginal', { data: new_obj }).then(({ data }) => {
        setRows(data)
      })
        .catch(err => {
          setLoading(false);
        });
    }
    if (item) {
      let mainIndex = basicList.findIndex(x => x.refId === item?._id);
      if (mainIndex >= 0) {
        return (
          <TableRow key={item?.id}>
            <TableCell ><input
              defaultValue={basicList[mainIndex].name}
              style={{ textAlign: 'left', borderWidth: 0, paddingBottom: 5, paddingTop: 5, width: '80%' }}
              onBlur={(e) => savefunction(e.target.value, mainIndex)}
              type="text"
              name="name" />
            </TableCell>
            <TableCell align={"right"}>
              <Checkbox
                checked={true}
                color="primary"
                onChange={(e) => Estimate_Delete(basicList[mainIndex], e)}
                sx={{
                  color: ' #ffcccc',
                  '&.Mui-checked': {
                    color: '#e60000',
                  },
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </TableCell>
          </TableRow>
        )

      }
    }
    return (
      <TableRow key={item?.id}>
        <TableCell >
          <input
            defaultValue={item.name}
            style={{ textAlign: 'left', borderWidth: 0, paddingBottom: 5, paddingTop: 5, width: '80%' }}
            // onBlur={(e) => savefunction(e.target.value, item.id)}
            type="text"
            name="name" />
        </TableCell>
        <TableCell align={"right"}>
          <Checkbox
            // checked={}
            color="primary"
            onChange={(e) => Estimate_Save(item, e)}
            sx={{
              color: ' #ffcccc',
              '&.Mui-checked': {
                color: '#e60000',
              },
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </TableCell>
      </TableRow>
    )
  }
  if (!rows) return null;
  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer ref={ref} component={Paper} className={classes.tableContainer}>
          <Grid container >
            <Grid item xs={12} sm={9}>
              <SectionHeading label="Tender Basic Details" classProp={classes.heading} />
            </Grid>
          </Grid>
          {rows && rows.tenderbasicdummy.length > 0 && rows.tenderbasicdummy.map((projectMaterial, index1) => (
            <Table className={classes.table} size="small" aria-label="material-table">
              <TableBody>
                <SingleCategoryViewNew item={projectMaterial} basicList={rows.tenderbasic} />
              </TableBody>
            </Table>
          ))}

          <Button
            style={{ backgroundColor: '#e5aa13', color: 'white', float: 'right', right: 20, marginTop: 25 }}
            variant="outlined"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={() => setIsCliked(!isClicked)}
          >
            save
          </Button>
        </TableContainer>
      </Paper>
    </>
  )
}

const SectionHeading = ({ label, classProp }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classProp}>
      <ListIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};


const useStyles = makeStyles({
  tableContainer: {
    marginTop: 15,
    paddingBottom: 15,
    boxShadow: 'none',
    border: '1px solid #ebebeb',
    width: 'auto',
    marginLeft: 10,
  },
  heading: {
    padding: 10,
    '& h6': {
      fontSize: 14,
      paddingLeft: 5,
      fontWeight: 'bold',
      letterSpacing: 1,
      color: '#e58f0a',
      textTransform: 'uppercase',
    }
  },
  table: {
    minWidth: 650,
  },
  deleteButton: {
    border: '1px solid #ff0000', color: '#ff0000', marginLeft: 15,
  },
  titlePaper: {
    background: '#f3f3f3',
    marginTop: 20,
    boxShadow: 'none',
  },
  projectTitle: {
    background: '#282a3c',
    color: '#fff',
    padding: 10,
    fontSize: 15,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
  },
});


export default TenderBasicList;
