import * as React from 'react';
import { Route } from 'react-router-dom';
import ProjectFiles from './ProjectFiles';
import ProjectTaskChart from './projects/ProjectTaskChart';
import ProjectTodoChart from './projects/ProjectTodoChart';
import ProjectMaterials from './projects/ProjectMaterials';
import ProjectCost from './projects/ProjectCost';
import ProjectMaterialEdit from './projects/MaterialEdit';
import ProjectVariationEdit from './projects/VariationEdit';
import ProjectCostEdit from './projects/CostEdit';
import PunchList from './projects/PunchList';
import RequestMaterial from './request';
import TaskConfirmation from './extras/confirmTask';
import ActivateAccount from './extras/activateAccount';
import ForgotPassword from './extras/forgot-password';
import ResetPassword from './extras/reset-password';
import ProjectForm from './extras/ProjectForm';
import PreContractList from './prec-contract/PreList';
import PreContractDetails from './prec-contract/PreDetails';
import InviteClient from './extras/InviteClient';
import ViewSchedules from './dashboard/ViewSchedules';
import Chat from './Chat';
import Message from './Message';
import Unit from './units/UnitList'
import Groups from './groups';
import Tenders from './tenders';
import TenderList from './tenders/list';
import TenderQuotes from './tenders/tenderQuotes';
import HomeTenders from './HomeTenders';
import ProjectVariation from './projects/ProjectVariation'
import ProjectEstimate from './projects/ProjectEstimate'
import LegalDocument from './projects/LegalDocuments'
import Category from './category/Category'
import MainCategory from './Maincategory/Maincategorylist'
import SubCategory from './Subcategory/SubcategoryList'
import EstimateEdit from './projects/EstimateEdit'
import ProjectTender from './projects/ProjectTender'
import TenderBasicDetails from './HomeTenders/TenderBasicdetails_list'
import TenderSiteMain from './HomeTenders/site_main/Tender-site_details_list'
import TenderLuxuryMain from './HomeTenders/luxury_main/Tender-luxury_details_list'
import TenderSiteSub from './HomeTenders/site_sub/Tender-site_details_list'
import TenderLuxurySub from './HomeTenders/Luxury_sub/Tender-luxury_details_list'
import TenderSiteDetails from './HomeTenders/site_categories'
import TenderLuxurySpecification from './HomeTenders/luxury_categories'
import TenderAuthoritycondition from './HomeTenders/authority/Tender-authority_details_list'
import TenderEstate from './HomeTenders/estateinclusion/Tender_estate_list'
import TermAndCondition from './HomeTenders/term-and-conditions/terms_and-condition_list'
import Notes from './HomeTenders/notes/Notes_list'
import AccountDetails from './HomeTenders/account/AccountDetails'
import ProcessFlow from './HomeTenders/process_flow/process_flow_list'
import CouncileFees from './HomeTenders/councile_authority_fees/councile_authority_fees_list'
import TenderAuthorityconditionNote from'./HomeTenders/authority_note/Tender-authority_details_list'
import AuthorityMenu from './HomeTenders/authority_condition_categories'
// import Checklist from './checklist/Checklist' //
import ChecklistCategory from './checklistcategory/Checklistcatlist'
import SubChecklist from './subchecklist/Subchecklistlist'
import Inspection from './inspection/InspectionList'
import FloorplanEdit from './projects/FloorplanEdit'
import Floorplan from './projects/Floorplan';
import QAinspectionList from './QAinspections/QAinspectionList';
import InspectionList from './QAinspections/inspectionList';
import ProjectsContacts from './project_contacts/Contacts';
import ProjectsContactsUpdate from './project_contacts/ContactsUpdate';

export default [
  <Route exact path="/project-schedule-chart/:id" render={(props) => <ProjectTaskChart id={props.match.params.id} {...props}/>} />,
  <Route exact path="/project-variations/:id" render={(props) => <ProjectVariation id={props.match.params.id} {...props}/>} />,
  <Route exact path="/project-legaldocuments/:id" render={(props) => <LegalDocument id={props.match.params.id} {...props}/>} />,
  <Route exact path="/project-estimate/:id/:revId" render={(props) => <ProjectEstimate id={props.match.params.id} RevId={props.match.params.revId} {...props}/>} />,
  <Route exact path="/project-tender/:id/:revId" render={(props) => <ProjectTender id={props.match.params.id} RevId={props.match.params.revId} {...props}/>} />,
  <Route exact path="/project-variations/edit/:id" render={(props) => <ProjectVariationEdit id={props.match.params.id} />} />,
  <Route exact path="/project-estimate/edit/:id" render={(props) => <EstimateEdit id={props.match.params.id} />} />,
  <Route exact path="/project-todo-chart/:id" render={({match}) => <ProjectTodoChart id={match.params.id} />} />,
  <Route exact path="/project-punch-list/:id" render={({match}) => <PunchList id={match.params.id} />} />,
  <Route exact path="/project-gallery/:id" render={(props) => <ProjectFiles projectId={props.match.params.id} {...props} />} />,
  <Route exact path="/project-costs/:id" render={({match}) => <ProjectCost projectId={match.params.id} />} />,
  <Route exact path="/project-materials/:id" render={({match}) => <ProjectMaterials projectId={match.params.id} />} />,
  <Route exact path="/project-materials/edit/:id" render={(props) => <ProjectMaterialEdit id={props.match.params.id} />} />,
  <Route exact path="/project-costs/edit/:id" render={(props) => <ProjectCostEdit id={props.match.params.id} />} />,
  <Route exact path="/request-material" render={(props) => <RequestMaterial />} />,
  <Route exact path="/today-schedules" render={(props) => <ViewSchedules />} />,
  <Route exact path="/pre-contracts" render={(props) => <PreContractList />} />,
  <Route exact path="/pre-contracts/:id" render={(props) => <PreContractDetails id={props.match.params.id}/>} />,
  <Route exact path="/invite-client" render={(props) => <InviteClient />} />,
  <Route exact path="/task-confirmation/:id" render={(props) => <TaskConfirmation {...props} taskId={props.match.params.id} />} />,
  <Route exact path="/activate-contractor/:id" render={(props) => <ActivateAccount {...props} />} noLayout/>,
  <Route exact path="/forgot-password" render={(props) => <ForgotPassword {...props} />} noLayout/>,
  <Route exact path="/reset-password" render={(props) => <ResetPassword {...props} />} noLayout/>,
  <Route exact path='/register-project' render={(props) => <ProjectForm {...props} /> } noLayout/>,
  <Route exact path="/client-chat" render={(props) => <Chat />} />,
  <Route exact path="/send-message" render={(props) => <Message />} />,
  <Route exact path="/units" render={(props) => <Unit {...props} />} />,
  <Route exact path="/categories" render={(props) => <Category {...props} />} />,
  <Route exact path="/maincategories" render={(props) => <MainCategory {...props} />} />,
  <Route exact path="/subcategories" render={(props) => <SubCategory {...props} />} />,
  <Route exact path="/basicdetails" render={(props) => <TenderBasicDetails {...props} />} />,
  <Route exact path="/site-details" render={(props) => <TenderSiteDetails {...props} />} />,
  <Route exact path="/luxury-specifications" render={(props) => <TenderLuxurySpecification {...props} />} />,
  <Route exact path="/estate-inclusion" render={(props) => <TenderEstate {...props} />} />,
  <Route exact path="/site-main" render={(props) => <TenderSiteMain {...props} />} />,
  <Route exact path="/luxury-main" render={(props) => <TenderLuxuryMain {...props} />} />,
  <Route exact path="/site-sub" render={(props) => <TenderSiteSub {...props} />} />,
  <Route exact path="/luxury-sub" render={(props) => <TenderLuxurySub {...props} />} />,
  <Route exact path="/groups" render={(props) => <Groups />} />,
  <Route exact path="/tenders" render={(props) => <Tenders />} />,
  <Route exact path="/posted-tenders" render={(props) => <TenderList />} />,
  <Route exact path="/tender-quotes" render={(props) => <TenderQuotes />} />,
  <Route exact path="/home-tenders" render={(props) => <HomeTenders />} />,
  <Route exact path="/authority-conditions" render={(props) => <TenderAuthoritycondition />} />,
  <Route exact path="/terms-conditions" render={(props) => <TermAndCondition />} />,
  <Route exact path="/notes" render={(props) => <Notes />} />,
  <Route exact path="/process-flow" render={(props) => <ProcessFlow />} />,
  <Route exact path="/council-fees" render={(props) => <CouncileFees />} />,
  <Route exact path="/authority" render={(props) => <AuthorityMenu/>} />,
  <Route exact path="/authority-notes" render={(props) => <TenderAuthorityconditionNote />} />,
  // <Route exact path="/checklist" render={(props) => <Checklist {...props} />} />,
  <Route exact path="/checklistcategory" render={(props) => <ChecklistCategory {...props} />} />,
  <Route exact path="/subchecklist" render={(props) => <SubChecklist {...props} />} />,
  <Route exact path="/inspection" render={(props) => <Inspection {...props} />} />,
  <Route exact path="/project-floorplan/:id" render={({ match }) => <Floorplan projectId={match.params.id} />} />,
  <Route exact path="/project-floorplan/edit/:id" render={(props) => <FloorplanEdit id={props.match.params.id} />} />,
  <Route exact path="/project-qa-Inspections/:id" render={(props) => <QAinspectionList id={props.match.params.id} />} />,
  <Route exact path="/project-Inspectionslist/:id/:qa_id" render={(props) => <InspectionList id={props.match.params.id} qaId={props.match.params.qa_id} />} />,
  <Route exact path="/project-contacts/:id" render={({ match }) => <ProjectsContacts projectId={match.params.id} />} />,
  <Route exact path="/project-contacts-edit/:id/:projectId" render={({ match }) => <ProjectsContactsUpdate id={match.params.id} projectId={match.params.projectId} />} />,
];
