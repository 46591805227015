import React, { useEffect, useState } from 'react';
import {
  SimpleForm,
  SelectInput,
  required,
  NumberInput,
  TextInput, useDataProvider
}
  from 'react-admin';
import { InputAdornment, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
  },
  heading: {
    paddingBottom: 10,
    '& h6': {
      fontSize: 14,
      paddingLeft: 5,
      fontWeight: 'bold',
      letterSpacing: 1,
      color: '#e58f0a',
      textTransform: 'uppercase',
    }
  },
}));

function MaterialCreate(props) {
  const { save } = props;
  const classes = useStyles();
  const [totalCost, setTotalCost] = useState(0);
  const onSave = (data) => {
    data['total'] = totalCost;
    save(data);
  };

  return (
    <SimpleForm save={onSave}>
      <SectionHeading label="Create Project Variation" classProp={classes.heading} />
      <GridField totalCost={totalCost}  setTotalCost={setTotalCost}/>
      <TextInput
        source="Description"
        label="Remarks"
        fullWidth={true}
        variant="outlined"
      // validate={required()}
      />

    </SimpleForm>
  )
}

const GridField = ({totalCost, setTotalCost}) => {
  const [cost, setCost] = useState();
  const [qty, setQty] = useState(0);
  const [unit, setUnit] = useState();
  const [unitselected, setUnitselected] = useState();
  const [loading, setLoading] = useState(true);
  // const [unitcost, setUnitcost] = useState();
  const [maincategory, setMaincategory] = useState(false);
  const [subcategory, setSubcategory] = useState();
  const dataProvider = useDataProvider();
  useEffect(() => {
    dataProvider.getList('unit', { filter: {}, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      setUnit(data);
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
    dataProvider.getList('maincategory', { filter: {}, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      setMaincategory(data);
    })
      .catch(error => {
        setLoading(false);
      });
  }, []);
  const getSubcat = (data) => {
    dataProvider.getList('subcategory', { filter: { maincategory: data }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      setSubcategory(data);

      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
  };
  const Newcost = (data) => {
    dataProvider.getList('subcategory', { filter: { _id: data }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      // setUnitcost(data[0]?.unitcost);
      setCost(data[0]?.unitcost)
      setUnitselected(data[0]?.unit?data[0].unit:null)
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
  }
  useEffect(() => {
    setTotalCost(qty * cost);
  }, [qty, cost]);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <SelectInput
          allowEmpty={true}
          emptyText="--Select category--"
          variant="outlined"
          label="MainCategory"
          source="maincategory"
          onChange={(e) => getSubcat(e.target.value)}
          fullWidth={true}
          choices={maincategory ? maincategory : []}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectInput
          allowEmpty={true}
          emptyText="--Select subcategory--"
          variant="outlined"
          label="SubCategory"
          source="subcategory"
          onChange={(e) => Newcost(e.target.value)}
          fullWidth={true}
          choices={subcategory ? subcategory : []}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <NumberInput
          source="cost"
          validate={required()}
          variant="outlined"
          onChange={(e) => setCost(e.target.value)}
          label="Cost"
          value={cost}
          fullWidth={true}
          initialValue={cost}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                AUD
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumberInput
          source="quantity"
          validate={required()}
          onChange={(e) => setQty(e.target.value)}
          variant="outlined"
          label="Quantity"
          fullWidth={true}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <SelectInput
          allowEmpty={true}
          emptyText="--Select unit--"
          variant="outlined"
          label="Unit"
          source="unit"
          fullWidth={true}
          initialValue={unitselected}
          choices={unit} />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumberInput
          disabled={true}
          source="total"
          value={totalCost}
          initialValue={totalCost}
          editable={false}
          validate={required()}
          variant="outlined"
          label="Total"
          fullWidth={true}
        />
      </Grid>
    </Grid>
  );
}


const SectionHeading = ({ label, classProp }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classProp}>
      <CreateIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

export default MaterialCreate;
