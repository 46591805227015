import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/List';
import SaveIcon from '@material-ui/icons/Save';
import {
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid, Typography, Checkbox
} from '@material-ui/core';
import {
  useDataProvider
}
  from 'react-admin';
const ref = React.createRef();

function TenderBasicList(props) {
  const { projectId, RevId, Prev_revId } = props;
  const dataProvider = useDataProvider();
  const [data, setdata] = useState([]);
  const [rows, setRows] = React.useState(false);
  const [qty, setQty] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const [isClicked, setIsCliked] = useState(false);
  useEffect(() => {
    dataProvider.getvariations('tender-luxuryspec', { filter: { project: projectId, revId: RevId, Prev_revId: Prev_revId }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      if (data&&data?.tender_luxury_saved&&data?.tender_luxury_saved.length>0&&data.tender_luxury_saved[0].revId !== RevId) {
        const new_obj = {
          Prev_revId: Prev_revId,
          revId: RevId,
          projectId: projectId,
        }
        dataProvider.newcreate1('tender-luxuryspec-array', { data: new_obj }).then(({ data }) => {
          setLoading(false);
          setRows(data)
        })
          .catch(err => {
            setLoading(false);
          });
      }
      else {
        setRows(data)
        setLoading(false);
      }
    })
      .catch(error => {
        setLoading(false);
      });
  }, [isClicked]);
  const SingleCategoryViewNew = ({ item, sitecost }) => {

    const savefunction = (change, estimateIndex, field_name) => {
      var new_object = {
        revId: RevId,
        Prev_revId: Prev_revId,
        projectId: projectId,
        id: sitecost[estimateIndex]?._id,
        created_at: sitecost[estimateIndex]?.created_at,
        _id: sitecost[estimateIndex]?._id,
        name: change,
        subRef: sitecost[estimateIndex]?.subRef,
      }
      dataProvider.updateEstimate('tender-luxuryspec', new_object).then(({ data }) => {
        setRows(data)
      })
    }

    const Estimate_Delete = (subcat, event) => {
      dataProvider.deleteEstimate('tender-luxuryspec', { id: subcat?.id, projectId: projectId, revId: RevId, Prev_revId: Prev_revId }).then(({ data }) => {
        setRows(data)
      })
        .catch(error => {
          setLoading(false);
        });
    }
    const Sitecost_Save = (subcat, event) => {
      const new_obj =
      {
        Prev_revId: Prev_revId,
        revId: RevId,
        subRef: subcat._id,
        name: subcat.name,
        projectId: projectId,
      }
      dataProvider.newcreate1('tender-luxuryspec', { data: new_obj }).then(({ data }) => {
        setRows(data)
      })
        .catch(err => {
          setLoading(false);
        });
    }
    if (item) {
      let mainIndex = sitecost.findIndex(x => x.subRef === item?._id);
      if (mainIndex >= 0) {
        return (
          <TableRow key={sitecost[mainIndex]?.id}>
            <TableCell >
              <input
                defaultValue={sitecost[mainIndex].name}
                style={{ textAlign: 'left', borderWidth: 0, paddingBottom: 5, paddingTop: 5, width: '80%' }}
                onBlur={(e) => savefunction(e.target.value, mainIndex)}
                type="text"
                name="name" />
            </TableCell>
            <TableCell align={"right"}>
              <Checkbox
                checked={true}
                color="primary"
                onChange={(e) => Estimate_Delete(sitecost[mainIndex], e)}
                sx={{
                  color: ' #ffcccc',
                  '&.Mui-checked': {
                    color: '#e60000',
                  },
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </TableCell>
          </TableRow>
        )

      }
    }
    return (
      <TableRow key={item?.id}>
        <TableCell >
          <input
            defaultValue={item?.name}
            style={{ textAlign: 'left', borderWidth: 0, paddingBottom: 5, paddingTop: 5, width: '80%' }}
            onBlur={(e) => savefunction(e.target.value, item)}
            type="text"
            name="name" />
        </TableCell>
        <TableCell align={"right"}>
          <Checkbox
            checked={false}
            color="primary"
            onChange={(e) => Sitecost_Save(item, e)}
            sx={{
              color: ' #ffcccc',
              '&.Mui-checked': {
                color: '#e60000',
              },
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </TableCell>
      </TableRow>
    )
  }
  if (!rows) return null;
  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer ref={ref} component={Paper} className={classes.tableContainer}>
          <Grid container >
            <Grid item xs={12} sm={9}>
              <SectionHeading label="Tender Luxury Specification Details" classProp={classes.heading} />
            </Grid>
          </Grid>
          {rows && rows.tender_luxury && rows.tender_luxury.length > 0 && rows.tender_luxury?.map((projectMaterial, index1) => (
            <>
              <Table className={classes.table} size="small" aria-label="material-table">
                <span style={{ marginLeft: 15, marginTop: 10, fontSize: 14, fontWeight: 'bold' }}>
                  {projectMaterial && projectMaterial.children && projectMaterial.children.length > 0 && projectMaterial?.name}
                </span>
                <TableBody>
                  {projectMaterial?.children && projectMaterial?.children?.length > 0 && projectMaterial?.children?.map((item) => {
                    return (
                      <SingleCategoryViewNew item={item} sitecost={rows.tender_luxury_saved} />
                    )
                  })}
                </TableBody>
              </Table>
            </>
          ))}

          <Button
            style={{ backgroundColor: '#e5aa13', color: 'white', float: 'right', right: 20, marginTop: 25 }}
            variant="outlined"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={() => setIsCliked(!isClicked)}
          >
            save
          </Button>
        </TableContainer>
      </Paper>
    </>
  )
}

const SectionHeading = ({ label, classProp }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classProp}>
      <ListIcon />
      <Typography variant="h6" style={{ marginTop: 5 }} gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};


const useStyles = makeStyles({
  tableContainer: {
    marginTop: 15,
    paddingBottom: 15,
    boxShadow: 'none',
    border: '1px solid #ebebeb',
    width: 'auto',
    marginLeft: 10,
  },
  heading: {
    padding: 10,
    '& h6': {
      fontSize: 14,
      paddingLeft: 5,
      fontWeight: 'bold',
      letterSpacing: 1,
      color: '#e58f0a',
      textTransform: 'uppercase',
    }
  },
  table: {
    minWidth: 650,
  },
  deleteButton: {
    border: '1px solid #ff0000', color: '#ff0000', marginLeft: 15,
  },
  titlePaper: {
    background: '#f3f3f3',
    marginTop: 20,
    boxShadow: 'none',
  },
  projectTitle: {
    background: '#282a3c',
    color: '#fff',
    padding: 10,
    fontSize: 15,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
  },
});


export default TenderBasicList;
