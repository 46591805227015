import React, { useEffect, useState } from 'react';
import {
  SimpleForm,
  required,
  DateInput,
  TextInput,
  RadioButtonGroupInput,
  useDataProvider,
}
  from 'react-admin';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
  },
  heading: {
    paddingBottom: 10,
    '& h6': {
      fontSize: 14,
      paddingLeft: 5,
      fontWeight: 'bold',
      letterSpacing: 1,
      color: '#e58f0a',
      textTransform: 'uppercase',
    }
  },
}));

function MaterialCreate(props) {
  // const { ids, data, basePath, resource } = useListContext();
  // console.log(data)
  const { save, projectId, unit } = props;
  const classes = useStyles();
  const [rows, setRows] = useState();
  const [initialval, setDefault] = useState(false);
  const [clientinfo, setClientinfo] = useState(false);
  const [Secondary_Client, setSecondary_Client] = useState(false);
  const [projectinfo, setProjectinfo] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [maincategory, setMaincategory] = useState(true);
  const dataProvider = useDataProvider();
  useEffect(() => {
    dataProvider.getProjectDetailInstance(projectId).then(({ data }) => {
      setClientinfo(data.project.client)
      setProjectinfo(data.project)
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
    dataProvider.getList('tender-user', { filter: { project: projectId }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      //  console.log(data)
      setDefault(data[0])
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
  }, [])
  const onSave = (data) => {
    data['project'] = projectId
    dataProvider.createCategory(data, 'tender-user').then(({ data }) => {
    }).catch(error => {

    });
    // tender-user
  };
  if (!clientinfo &&!projectinfo) return null;
  return (
    <SimpleForm save={onSave}>
      <SectionHeading label="Tender Basic Details" classProp={classes.heading} />
      {initialval?
      <GridField initialval={initialval} />
      :
      <GridField initialval={
        {prepared_date:new Date(),
          acceptance_date:new Date(),
          councile:'',
          developer_condact:'',
          developer_approval:'',
          landregistration:'',
          building_contract:'',
          development_application:'',
        }} />
      }
      {/* <span>{'For Owner(s)'}</span> */}
      {/* {initialval?
      <Grid_Field user={initialval} />
      :
      <Grid_Field user={clientinfo} />
      } */}
      {/* {clientinfo && 
      <Grid_Field initialval={initialval} user={clientinfo} />
      } */}
      {/* <TextInput
        source="construct"
        label="To Construct"
        fullWidth={true}
        variant="outlined"
        validate={required()}
        defaultValue={projectinfo?.description}
      />
      {projectinfo&&
      <TextInput
        source="at"
        label="At"
        fullWidth={true}
        variant="outlined"
        validate={required()}
        defaultValue={`${projectinfo?.street},${projectinfo?.suburb},${projectinfo?.zip}`}
      />
      } */}
    </SimpleForm>
  )
}

const GridField = (props) => {
  // console.log(props)
  const { initialval } = props;
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="preparedby"
          label="Prepared By"
          fullWidth={true}
          variant="outlined"
          validate={required()}
          defaultValue={initialval?.preparedby}

        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <DateInput
          allowEmpty={true}
          variant="outlined"
          label="Tender Prepared"
          source="prepared_date"
          fullWidth={true}
          // defaultValue={new Date()}
          defaultValue={initialval?.prepared_date}

        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <DateInput
          allowEmpty={true}
          variant="outlined"
          label="Tender Acceptance"
          source="acceptance_date"
          fullWidth={true}
          defaultValue={initialval?.acceptance_date}

        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="councile"
          label="Council"
          fullWidth={true}
          variant="outlined"
          validate={required()}
          defaultValue={initialval?.councile}

        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="developer_condact"
          label="Developer Contact"
          fullWidth={true}
          variant="outlined"
          validate={required()}
          defaultValue={initialval?.developer_condact}

        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <RadioButtonGroupInput
          style={{ marginTop: -5, marginLeft: 40 }}
          source="developer_approval"
          label="Developer Approval"
          defaultValue={initialval?.developer_approval}

          choices={[
            { id: 'yes', name: 'Yes' },
            { id: 'no', name: 'No' },
          ]} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="landregistration"
          label="Land Registration"
          fullWidth={true}
          variant="outlined"
          validate={required()}
          defaultValue={initialval?.landregistration}

        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="building_contract"
          label="Building Contract"
          fullWidth={true}
          variant="outlined"
          validate={required()}
          defaultValue={initialval?.building_contract}

        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="development_application"
          label="Development Application"
          fullWidth={true}
          variant="outlined"
          validate={required()}
          defaultValue={initialval?.development_application}

        />
      </Grid>
    </Grid>
  );
}
const Grid_Field = (props) => {
  const { initialval, user } = props;
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="name"
          label="Name"
          fullWidth={true}
          variant="outlined"
          validate={required()}
          defaultValue={user?.name}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="email"
          label="Email"
          fullWidth={true}
          variant="outlined"
          validate={required()}
          defaultValue={user?.email}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="phone"
          label="Phone"
          fullWidth={true}
          variant="outlined"
          validate={required()}
          defaultValue={user.phone?user.phone:user?.contact}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput
          source="address"
          label="Address"
          fullWidth={true}
          variant="outlined"
          validate={required()}
          defaultValue={user.address?`${user.address}`:`${user?.street},${user?.suburb},${user?.zip}`}

        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="name_2"
          label="Name"
          fullWidth={true}
          variant="outlined"
          defaultValue={initialval?.name_2}

        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="email_2"
          label="Email"
          fullWidth={true}
          variant="outlined"
          defaultValue={initialval?.email_2}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="phone_2"
          label="Phone"
          fullWidth={true}
          variant="outlined"
          defaultValue={initialval?.phone_2}

        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput
          source="address_2"
          label="Address"
          fullWidth={true}
          variant="outlined"
          defaultValue={initialval?.address_2}

        />
      </Grid>
    </Grid>
  );
}

const SectionHeading = ({ label, classProp }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classProp}>
      <CreateIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

export default MaterialCreate;
