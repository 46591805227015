import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/List';
import PrintIcon from '@material-ui/icons/Print';
import SaveIcon from '@material-ui/icons/Save';
import { AiOutlinePercentage } from "react-icons/ai";
import { MdAttachMoney } from "react-icons/md";
import {
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Button,
  Grid, Typography,
  Checkbox,
  Dialog, DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import {
  useDataProvider, useAuthProvider, TextInput
} from 'react-admin';
import { Document, Page, Text, View, Image, Font } from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  FcGenealogy
} from "react-icons/fc";
import { useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';
import AdditionalCreate from './Additional_create'

const ref = React.createRef();
Font.register({
  family: 'newfont', fonts: [
    { src: process.env.PUBLIC_URL + '/ARIAL.ttf' }, // font-style: normal, font-weight: normal
    // { src: process.env.PUBLIC_URL + '/TimesNewRoman.ttf',},
  ]
});
function SimpleDialog(props) {
  const { onClose, selectedValue, open, revisions, id, Prev_revId, RevId } = props;
  const routeHistory = useHistory();
  const dataProvider = useDataProvider();
  const authProvider = useAuthProvider();
  const user = authProvider.getIdentity();
  const classes = useStyles();
  const handleClose = () => {
    onClose(selectedValue);
  };
  const CreateGroup = (values) => {
    values['project'] = id;
    values['prepared_by'] = user.username;
    values['Prev_revId'] = RevId;
    dataProvider.createCategory(values, 'project-estimate-rev').then(({ data }) => {
      routeHistory.push(`/project-estimate/${id}/${data.id}`)
      handleClose()
    }).catch(error => {

    });
  }
  return (
    <>

      <Dialog onClose={handleClose} open={open}>
        <DialogTitle id="form-dialog-title">
          <Grid container direction="row" alignItems="center" >
            <div className={classes.heading} >
              <FcGenealogy />
              <Typography variant="h6" gutterBottom>
                Create Revision
              </Typography>
            </div>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.modalContent}>
          <Form
            onSubmit={CreateGroup}
            render={({ handleSubmit, form }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <TextInput
                        source="rev_no"
                        label="Rev No"
                        fullWidth={true}
                        variant="outlined"
                      // validate={required()}
                      // defaultValue={initialval?.rev_no}

                      />
                    </Grid>
                    <div className={classes.saveBtnHolder}>
                      <Button className={classes.saveBtn} type="submit"><SaveIcon style={{ fontSize: 17, marginRight: 2 }} /> SAVE</Button>
                    </div>
                  </Grid>
                </form>
              )
            }} />
        </DialogContent>
      </Dialog>

    </>
  );
}

function MaterialList(props) {
  const { data, value, projectId, projectdetails, maincategory, setMaincategory, RevId, Prev_revId } = props;
  const dataProvider = useDataProvider();
  const authProvider = useAuthProvider();
  const user = authProvider.getIdentity();
  const [grand, setGrand] = useState();
  const [buttonvalue, setButtonvalue] = useState();
  const [totalbuildcost, setTotalbuildcost] = useState();
  const [changed, setChanged] = useState(false);
  const [percentage, setPercentage] = useState();
  const [fixed, setFixed] = useState();
  const [flag, setFlag] = React.useState(true);
  const [PdfList, setPdfList] = React.useState(false);
  const [defaultdiscount, setDefaultdiscount] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [cost, setCost] = useState(false);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState([1]);
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  useEffect(() => {
    setLoading(true)
    var project_total = 0
    // maincategory && maincategory.category_saved.length > 0 && maincategory.category_saved.map((item) => {
    //   project_total = project_total + (item?.unitcost * item?.quantity)
    // })
    const NewCategorylist = []
    let additional_sum = 0
    maincategory.category && maincategory.category.length > 0 && maincategory.category.map((projectMaterial, index1) => {
      if (projectMaterial?.additionalcategory) {
        let additional = maincategory?.additionals?.length > 0 && maincategory.additionals.map((item) => {
          if (item.status === true) {
            additional_sum = additional_sum + (item.unitcost * item.quantity)
          }
        })
      }
      // console.log(additional_sum);
      const subArr = []
      var sub_total = 0
      projectMaterial.children && projectMaterial.children.length > 0 && projectMaterial.children.map((item, index2) => {
        const val = Single_element_return(item, projectMaterial?.maincategory?.id, maincategory.category_saved)
        if (val) {
          subArr.push(val)
          sub_total = sub_total + (val.unitcost * val.quantity)
        }
      })
      if (subArr && subArr.length > 0) {
        if (projectMaterial.name !== 'AUTHORITYCONDITION') {
          if (projectMaterial?.additionalcategory) {
            project_total = project_total + sub_total + additional_sum
          }
          else {
            project_total = project_total + sub_total
          }
          NewCategorylist.push({ children: subArr, MaincategorySum: sub_total, additionalcategory: projectMaterial.additionalcategory, basecategory: projectMaterial.basecategory, created_at: projectMaterial.created_at, _id: projectMaterial._id, name: projectMaterial.name })
        }
      }
      else {
        if (projectMaterial?.additionalcategory) {
          project_total = project_total + sub_total + additional_sum
          NewCategorylist.push({ children: [], MaincategorySum: sub_total, additionalcategory: projectMaterial.additionalcategory, basecategory: projectMaterial.basecategory, created_at: projectMaterial.created_at, _id: projectMaterial._id, name: projectMaterial.name })
        }
      }
    })
    // console.log(project_total)
    setPdfList(NewCategorylist)
    setLoading(false)
    setGrand(project_total)
    setTotalbuildcost(project_total)

    dataProvider.getvariations('estimate-discount', { filter: { project: projectId }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      setCost(data?.sitebaseprice[0]?.cost)
      setLoading(false);
      if (data.discount[0]?.percentage === true) {
        setButtonvalue(true)
        Discount(data?.discount[0]?.discount, 'percentage', data?.sitebaseprice[0]?.cost)
      }
      else if (data?.discount[0]?.percentage === false) {
        setButtonvalue(false)
        Discount(data.discount[0]?.discount, 'fixed', data?.sitebaseprice[0]?.cost)
      }
      else {
        setTotalbuildcost(grand - data?.sitebaseprice[0]?.cost)
      }
    })
      .catch(error => {
        setLoading(false);
      });

  }, [maincategory, grand]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  }
  const Single_element_return = (item, mainCatId, estimate) => {
    if (item) {
      let mainIndex = estimate.findIndex(x => x?.subcatId?.id === item._id);
      if (mainIndex >= 0) {
        return estimate[mainIndex]
      }
    }
    return false
  }
  // const EstimateSave = () => {
  //   var new_object = {
  //     project: projectId,
  //     rev_no: '',
  //     prepared_by: user.username,
  //   }
  //   dataProvider.newcreate1('project-estimate-rev', { new_object }).then(({ data }) => {
  //     window.location.reload()
  //   })
  //     .catch(err => {
  //       setLoading(false);
  //     });
  // }
  const Discount = (data, type, baseprice) => {
    // console.log(data, type, baseprice)
    if (type === 'percentage' && data > 0) {
      setTotalbuildcost((grand - baseprice) * ((100 - data) / 100))
      setPercentage(data)
      setFixed(false)
    }
    else if (type === 'fixed' && data > 0) {
      setTotalbuildcost(grand - baseprice - data)
      setFixed(data)
      setPercentage(false)

    }
    else {
      setTotalbuildcost(grand - baseprice)
      setPercentage(0)
      setFixed(0)
    }
  }
  const SaveDiscount = () => {
    if (!fixed) {
      dataProvider.createCategory(
        {
          discount: percentage,
          percentage: true,
          project: projectId,
        }
        , 'estimate-discount').then(({ data }) => {
        })
        .catch(error => {
          setLoading(false);
        });
    }
    else {
      dataProvider.createCategory(
        {
          discount: fixed,
          percentage: false,
          project: projectId,
        }
        , 'estimate-discount').then(({ data }) => {
        })
        .catch(error => {
          setLoading(false);
        });
    }
  }
  const MyDocument = () => {
    return (
      <Document>
        <Page size="A4" style={{
          paddingBottom: 50,
        }}>
          <View fixed style={{ marginBottom: 30 }}>
            <Image src={process.env.PUBLIC_URL + '/construction stage 1.jpg'} />
          </View>
          <View style={classes.section}>
            <View style={{ textAlign: 'center', paddingLeft: 10, paddingRight: 10 }}>
              <Text gutterBottom style={{ fontSize: 28, textDecorationLine: 'underline', fontFamily: 'Helvetica-Bold', borderBottom: '1.8px solid black' }} >
                {"BREAKDOWN LIST"}
              </Text>
            </View>
            <View style={{ textAlign: 'center', paddingLeft: 10, paddingRight: 10 }}>
              <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 23, textAlign: 'center', borderBottom: '1.5px solid black' }}>
                {`${projectdetails.title}, ${projectdetails.description}`}
              </Text>
            </View>
            <ul style={{ fontSize: 20, paddingLeft: 60, paddingRight: 60, marginTop: 20 }}>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 14, fontWeight: '600', fontFamily: 'Helvetica-Bold', marginLeft: 15, flex: 5 }}>{'Name'}</Text>
                <Text style={{ fontSize: 14, fontWeight: '600', fontFamily: 'Helvetica-Bold', marginLeft: 15, flex: 1 }}>{'Qty'}</Text>
                <Text style={{ fontSize: 14, fontWeight: '600', fontFamily: 'Helvetica-Bold', marginLeft: 15, flex: 1 }}>{'Unit'}</Text>
                <Text style={{ fontSize: 14, fontWeight: '600', fontFamily: 'Helvetica-Bold', marginLeft: 15, flex: 1 }}>{'Price'}</Text>
              </View>
              {PdfList && PdfList.length > 0 && PdfList.map((projectMaterial, index1) => (
                <div key={index1} style={{ display: 'flex', flexDirection: 'row' }}>
                  <li style={{ flex: 3, marginTop: 10, marginBottom: 10 }} >
                    <Text gutterBottom style={{ fontFamily: 'Helvetica-Bold', fontSize: 16 }} >
                      {index1 + 1}{'.'}{projectMaterial?.name?.toUpperCase()}
                    </Text>
                    <ul style={{ listStyleType: "lower-roman", marginTop: 10 }}>
                      {projectMaterial.children && projectMaterial.children.length > 0 &&
                        projectMaterial.children.map((item, index2) => (
                          <View key={index2} style={{ flexDirection: 'row' }}>
                            <Text style={{ fontSize: 14, fontWeight: '600', marginLeft: 15, flex: 5 }}>{index2 + 1}{'.'}{item?.subcatId?.name}</Text>
                            <Text style={{ fontSize: 14, fontWeight: '600', marginLeft: 15, flex: 1 }}>{item?.quantity}</Text>
                            <Text style={{ fontSize: 14, fontWeight: '600', marginLeft: 15, flex: 1 }}>{item?.subcatId?.unit?.name}</Text>
                            {projectMaterial?.name === 'SITECOST' ?
                              <Text style={{ fontSize: 14, fontWeight: '600', fontFamily: 'Helvetica-Bold', marginLeft: 15, flex: 1 }}>{formatter.format((item?.unitcost * item?.quantity) - cost)}</Text>
                              :
                              <Text style={{ fontSize: 14, fontWeight: '600', fontFamily: 'Helvetica-Bold', marginLeft: 15, flex: 1 }}>{formatter.format(item?.unitcost * item?.quantity)}</Text>
                            }
                            {/* <Text style={{ fontSize: 14, fontWeight: '600', fontFamily: 'Helvetica-Bold', marginLeft: 15, flex: 1 }}>{formatter.format(item?.unitcost * item?.quantity)}</Text> */}
                          </View>
                        ))}
                      {projectMaterial.additionalcategory && maincategory.additionals && maincategory.additionals.length > 0 && maincategory.additionals.map((item, index) => {
                        if (item.status === true) {
                          return (
                            <View key={index} style={{ flexDirection: 'row' }}>
                              <Text style={{ fontSize: 14, fontWeight: '600', marginLeft: 15, flex: 5 }}>{projectMaterial.children.length + index + 1}{'.'}{item?.name}</Text>
                              <Text style={{ fontSize: 14, fontWeight: '600', marginLeft: 15, flex: 1 }}>{item?.quantity}</Text>
                              <Text style={{ fontSize: 14, fontWeight: '600', marginLeft: 15, flex: 1 }}>{item?.unit}</Text>
                              <Text style={{ fontSize: 14, fontWeight: '600', fontFamily: 'Helvetica-Bold', marginLeft: 15, flex: 1 }}>{formatter.format(item?.unitcost * item?.quantity)}</Text>
                            </View>
                          )
                        }
                      }
                      )}
                    </ul>
                  </li>
                  {/* <Text style={{ flex: 1.4, alignItems: 'flex-end', marginTop: 10, marginLeft: 80, fontSize: 16, fontFamily: 'Helvetica-Bold' }}>
                    {`${formatter.format(projectMaterial.unitcost && projectMaterial.quantity ? (projectMaterial.unitcost * projectMaterial.quantity) : 0)}`}
                  </Text> */}
                </div>
              ))}
              <Text style={{ fontSize: 14, fontWeight: '600', marginLeft: 5, marginBottom: 10 }}>
                {'As per the location of the lot, site condition, luxury inclusions(reference images and the wish list sent by the client)'}
              </Text>
              <View style={{ flex: 1, borderTop: '1px solid black', marginTop: 15, marginBottom: 10 }}></View>
              {/* <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 18, marginLeft: 200, marginBottom: 10, alignItems: 'flex-end' }}>
             
            </Text> */}
              <View style={{ alignSelf: 'flex-end' }}>
                {totalbuildcost !== grand - (cost ? cost : 0) ?
                  <div style={{ alignItems: 'flex-end' }}>
                    <View style={{ flexDirection: 'row', display: 'flex' }}>
                      <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 18, width: 200 }}>
                        {`TOTAL BUILT COST`}
                      </Text>
                      <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 18, width: 20 }}>
                        {` : `}
                      </Text>
                      <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 18, width: 120 }} >
                        {formatter.format(grand ? grand - (cost ? cost : 0) : 0)}
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', display: 'flex' }}>
                      <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 18, width: 200 }}>
                        {`DISCOUNT OF `}
                      </Text>
                      <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 18, width: 20 }}>
                        {` : `}
                      </Text>
                      <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 18, width: 120 }} >
                        {percentage ? percentage : ''}{percentage ? ' %' : ''}{fixed ? formatter.format(fixed) : ''}
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', display: 'flex' }}>
                      <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 18, width: 200 }}>
                        {`FINAL SUM `}
                      </Text>
                      <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 18, width: 20 }}>
                        {` : `}
                      </Text>
                      <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 18, width: 120 }}>
                        {` ${formatter.format(totalbuildcost ? totalbuildcost : 0)} `}
                      </Text>
                    </View>

                  </div>
                  :
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 18 }}>
                    {`TOTAL BUILT COST :${formatter.format(totalbuildcost ? totalbuildcost : 0)}`}
                  </Text>}
              </View>

            </ul>

          </View>
          <Text gutterBottom style={{ position: 'absolute', bottom: 40, left: 40, fontSize: 14, fontFamily: 'Helvetica-Bold' }} >
            {`Prepared by : ${user.username}`}
          </Text>
          <View fixed style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            paddingTop: 20,
            zIndex: 0,
            textAlign: 'center',
          }}>
            <Image src={process.env.PUBLIC_URL + '/construction stage 2.jpg'} />
          </View>
        </Page>
      </Document>
    )
  }

  const SingleCategoryViewNew = ({ item, mainCatId, estimate }) => {

    const savefunction = (change, estimateIndex, field_name) => {
      // console.log(estimate[estimateIndex])
      var new_object = {
        projectId: projectId,
        id: estimate[estimateIndex]?._id,
        created_at: estimate[estimateIndex].created_at,
        maincategory: estimate[estimateIndex].maincategory,
        _id: estimate[estimateIndex]._id,
        name: estimate[estimateIndex].name,
        unit: estimate[estimateIndex].unit,
        subcatId: estimate[estimateIndex].subcatId,
        unitcost: field_name === 'unitcost' ? Number(change) : estimate[estimateIndex].unitcost,
        quantity: field_name === 'quantity' ? Number(change) : estimate[estimateIndex].quantity,
        revId: RevId
      }
      dataProvider.updateEstimate('project-estimate', new_object).then(({ data }) => {

        setMaincategory(data)
      })
      setFlag(!flag)
      setChanged(!changed)
    }
    const Estimate_Delete = (subcat, event) => {
      dataProvider.deleteEstimate('project-estimate', { id: subcat?.id, projectId: projectId, revId: RevId }).then(({ data }) => {
        setMaincategory(data);
        console.log(data);
      })
        .catch(error => {
          setLoading(false);
        });
      setFlag(!flag)
    }
    const Estimate_Save = (subcat, event) => {
      const new_obj =
      {
        subcatId: subcat._id,
        quantity: subcat?.quantity || 1,
        unit: subcat?.unit?.id,
        unitcost: subcat?.unitcost,
        projectId: projectId,
        revId: RevId
      }
      dataProvider.newcreate1('project-estimate', { data: new_obj }).then(({ data }) => {
        setMaincategory(data)
      })
        .catch(err => {
          setLoading(false);
        });
      setFlag(!flag)
    }
    if (item) {
      let mainIndex = estimate.findIndex(x => x.subcatId?.id === item?._id);
      if (mainIndex >= 0) {
        return (
          <TableRow key={estimate[mainIndex]?.id}>
            <TableCell > {estimate[mainIndex]?.subcatId && estimate[mainIndex]?.subcatId.name}</TableCell>
            <TableCell align="center">
              {`$`}
              <input
                defaultValue={estimate[mainIndex]?.unitcost}
                style={{ textAlign: 'center', borderWidth: 0, paddingBottom: 5, paddingTop: 5 }}
                onBlur={(e) => savefunction(e.target.value, mainIndex, 'unitcost')}
                type="text"
                name="number" />
            </TableCell>
            <TableCell align="center">
              <input
                defaultValue={estimate[mainIndex]?.quantity}
                style={{ textAlign: 'center', borderWidth: 0, paddingBottom: 5, paddingTop: 5 }}
                onBlur={(e) => savefunction(e.target.value, mainIndex, 'quantity')}
                type="text"
                name="number" />
            </TableCell>
            <TableCell align="center">
              {item?.unit?.name}
            </TableCell>
            <TableCell align="center">
              {formatter.format(estimate[mainIndex]?.unitcost * estimate[mainIndex]?.quantity)}
            </TableCell>
            <TableCell align={"right"}>
              <Checkbox
                checked={true}
                color="primary"
                onChange={(e) => Estimate_Delete(estimate[mainIndex], e)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </TableCell>
          </TableRow>
        )

      }
    }
    return (
      <TableRow key={item?.id}>
        <TableCell >{item?.id} {item?.name}</TableCell>
        <TableCell align="center">
          {`$`}
          <input
            defaultValue={item?.unitcost}
            style={{ textAlign: 'center', borderWidth: 0, paddingBottom: 5, paddingTop: 5 }}
            type="text"
            name="number" />
        </TableCell>
        <TableCell align="center">
          <input
            defaultValue={1}
            style={{ textAlign: 'center', borderWidth: 0, paddingBottom: 5, paddingTop: 5 }}
            type="text"
            name="number" />
        </TableCell>
        <TableCell align="center">
          {item?.unit?.name}
        </TableCell>
        <TableCell align="center">
          {formatter.format(item?.unitcost * (item?.quantity ? item?.quantity : 1))}
        </TableCell>
        <TableCell align={"right"}>
          <Checkbox
            color="primary"
            onChange={(e) => Estimate_Save(item, e)}
            sx={{
              color: ' #ffcccc',
              '&.Mui-checked': {
                color: '#e60000',
              },
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </TableCell>
      </TableRow>
    )
  }
  const Update_Additionals = (item, e) => {
    dataProvider.updateEstimate('estimate-additionals', { id: item?.id, status: e.target.checked, project: projectId, revId: RevId }).then(({ data }) => {
      setMaincategory(data)
    })
      .catch(error => {
        console.log(error)
      });
  }
  const SaveAdditionals = (e, item, type) => {
    if (type === 'quantity') {
      dataProvider.updateEstimate('estimate-additionals', { id: item?.id, quantity: e, project: projectId, revId: RevId }).then(({ data }) => {
        setMaincategory(data)
      })
        .catch(error => {
          console.log(error)
        });
    }
    else {
      dataProvider.updateEstimate('estimate-additionals', { id: item?.id, unitcost: e, project: projectId, revId: RevId }).then(({ data }) => {
        setMaincategory(data)
      })
        .catch(error => {
          console.log(error)
        });
    }


  }
  if (!projectdetails && !grand && !maincategory) return null;
  return (
    <>
      <TableContainer ref={ref} component={Paper} className={classes.tableContainer}>
        <Grid container >
          <Grid item xs={12} sm={9}>
            <SectionHeading label="Estimate" classProp={classes.heading} />
          </Grid>
        </Grid>
        <>

          <Table className={classes.table} size="small" aria-label="material-table">
            {maincategory.category && maincategory?.category?.length > 0 && maincategory?.category?.map((projectMaterial, index1) => (
              <>
                <div style={{ position: 'absolute', right: 40, marginTop: 15 }}>
                  {projectMaterial && projectMaterial.additionalcategory && <AdditionalCreate setMaincategory={setMaincategory} projectId={projectId} RevId={RevId} className={classes.buttonEnd} />}
                </div>
                {projectMaterial?.children?.length === 1 && !projectMaterial?.children[0].name ?
                  <></>
                  :
                  <>
                    <TableHead>
                      <Typography variant="h6" gutterBottom style={{ padding: 15 }} >
                        {projectMaterial?.children && projectMaterial?.children.length > 0 && projectMaterial?.name}
                      </Typography>
                      <TableRow>
                        <TableCell style={{ fontWeight: '600', width: 180 }}>Description</TableCell>
                        <TableCell align="center" style={{ fontWeight: '600' }}>Cost</TableCell>
                        <TableCell align="center" style={{ fontWeight: '600' }}>Quantity</TableCell>
                        <TableCell align="center" style={{ fontWeight: '600' }}>Unit</TableCell>
                        <TableCell align="center" style={{ fontWeight: '600' }}>Total</TableCell>
                        <TableCell align="center"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {projectMaterial?.children && projectMaterial?.children?.length > 0 &&
                        projectMaterial?.children?.map((item, index2) => {

                          if (item?.name) {
                            return (
                              <SingleCategoryViewNew key={index2} item={item} mainCatId={projectMaterial?.maincategory?.id} estimate={maincategory.category_saved} />
                            )
                          } else {
                            return null;
                          }

                        })
                      }
                    </TableBody>
                  </>
                }
                {projectMaterial && projectMaterial.additionalcategory && maincategory.additionals ?
                  maincategory.additionals.map((item) => {
                    return (
                      <TableRow key={item?.id}>
                        <TableCell >{item?.name}</TableCell>
                        <TableCell align="center">
                          {`$`}
                          <input
                            defaultValue={item?.unitcost}
                            style={{ textAlign: 'center', borderWidth: 0, paddingBottom: 5, paddingTop: 5 }}
                            onBlur={(e) => SaveAdditionals(e.target.value, item, 'unitcost')}
                            type="text"
                            name="number" />
                        </TableCell>
                        <TableCell align="center">
                          <input
                            defaultValue={item?.quantity}
                            style={{ textAlign: 'center', borderWidth: 0, paddingBottom: 5, paddingTop: 5 }}
                            onBlur={(e) => SaveAdditionals(e.target.value, item, 'quantity')}
                            type="text"
                            name="number" />
                        </TableCell>
                        <TableCell align="center">
                          {item?.unit}
                        </TableCell>
                        <TableCell align="center">
                          {formatter.format(item?.unitcost * (item?.quantity ? item?.quantity : 1))}
                        </TableCell>
                        <TableCell align={"right"}>
                          <Checkbox
                            checked={item?.status ? item?.status : false}
                            color="primary"
                            onChange={(e) => { Update_Additionals(item, e) }}
                            // Estimate_Save(item, e)
                            sx={{
                              color: ' #ffcccc',
                              '&.Mui-checked': {
                                color: '#e60000',
                              },
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </TableCell>
                      </TableRow>
                    )

                  })
                  : null}
              </>
            ))}
          </Table>
        </>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30 }}>
          <div style={{ marginRight: 20, marginLeft: 20, fontWeight: 'bold' }} >
            {`Discount`}
          </div>
          {buttonvalue ?
            <>
              <input
                style={{ textAlign: 'center', borderWidth: 1, marginLeft: 15 }}
                onBlur={(e) => Discount(e.target.value, 'percentage', cost)}
                type="text"
                name="percentage_discount"
                defaultValue={percentage}
              />
            </>
            :
            <>
              <input
                style={{ textAlign: 'center', borderWidth: 1, marginLeft: 15 }}
                onBlur={(e) => Discount(e.target.value, 'fixed', cost)}
                type="text"
                defaultValue={fixed}
                name="fixed_discount" />
            </>
          }
          <Button
            style={{ backgroundColor: buttonvalue ? '#e5aa13' : '#ccc', color: 'white', marginLeft: 25 }}
            variant="outlined"
            color="primary"
            // startIcon={<AttachMoneyIcon />}
            onClick={() => setButtonvalue(true)}
          >

            <AiOutlinePercentage size={18} />
          </Button>

          <Button
            style={{ backgroundColor: buttonvalue ? '#ccc' : '#e5aa13', color: 'white', marginLeft: 20 }}
            variant="outlined"
            color="primary"
            // startIcon={<AttachMoneyIcon />}
            onClick={() => setButtonvalue(false)}
          >

            <MdAttachMoney size={18} />
          </Button>
          <Button
            style={{ backgroundColor: '#e5aa13', color: 'white', marginLeft: 25 }}
            variant="outlined"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={() => SaveDiscount()}
          >
            Save
          </Button>
        </div>
        <div style={{ fontWeight: 'bold', fontSize: 20, marginLeft: 20, marginBottom: 10, marginTop: 20, }}>
          {totalbuildcost !== grand - cost ?
            <div>
              <div>
                {`TOTAL BUILT COST : ${formatter.format(grand - cost)}`}
              </div>
              <div>
                {`DISCOUNT OF : ${percentage ? percentage : ''}${percentage ? ' %' : ''} ${fixed ? formatter.format(fixed) : ''}`}
              </div>
              <div>
                {`FINAL SUM : ${formatter.format(totalbuildcost)}`}
              </div>
            </div>
            :
            <div style={{ fontWeight: 'bold', fontSize: 20, marginLeft: 20, marginBottom: 10, marginTop: 20, }}>
              {`TOTAL BUILT COST : ${formatter.format(totalbuildcost)}`}
            </div>}
        </div>
        <Button
          style={{ backgroundColor: '#e5aa13', color: 'white', float: 'right', right: 20, marginTop: 25 }}
          variant="outlined"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={() => handleClickOpen()}
        >
          save
        </Button>
        <SimpleDialog
          selectedValue={selectedValue}
          open={open}
          id={projectId}
          revisions={data || []}
          Prev_revId={Prev_revId}
          RevId={RevId}
          onClose={handleClose}
        />
      </TableContainer>
      <Button
        variant="outlined"
        startIcon={<PrintIcon />}
        style={{ marginTop: 10, marginBottom: 10 }}
        className={classes.deleteButton}
      >
        <PDFDownloadLink
          document={<MyDocument />}
          fileName="Estimate.pdf">
          {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
        </PDFDownloadLink>
      </Button>
    </>
  )
}

const SectionHeading = ({ label, classProp }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classProp}>
      <ListIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};


const useStyles = makeStyles({
  tableContainer: {
    marginTop: 15,
    paddingBottom: 15,
    boxShadow: 'none',
    border: '1px solid #ebebeb',
    width: 'auto',
    marginLeft: 10,
  },
  heading: {
    padding: 10,
    '& h6': {
      fontSize: 14,
      paddingLeft: 5,
      fontWeight: 'bold',
      letterSpacing: 1,
      color: '#e58f0a',
      textTransform: 'uppercase',
    }
  },
  table: {
    minWidth: 650,
  },
  deleteButton: {
    border: '1px solid #ff0000', color: '#ff0000', marginLeft: 15,
  },
  titlePaper: {
    background: '#f3f3f3',
    marginTop: 20,
    boxShadow: 'none',
  },
  projectTitle: {
    background: '#282a3c',
    color: '#fff',
    padding: 10,
    fontSize: 15,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
  },
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    paddingBottom: 40
  },
  section: {
    margin: 10,
    padding: 10,
    flex: 1,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 100,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald',
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  saveBtnHolder: {
    marginTop: 20,
    padding: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  saveBtn: {
    background: '#e5aa13',
    color: '#000',
    fontWeight: 'bold',
    '&:hover': {
      background: '#e5aa13',
    }
  },
  buttonEnd: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});


export default MaterialList;


