import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/List';
import SaveIcon from '@material-ui/icons/Save';

import {
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid, Typography, Checkbox
} from '@material-ui/core';
import {
  useDataProvider
}
  from 'react-admin';
const ref = React.createRef();

function TenderBasicList(props) {
  const { projectId, RevId, Prev_revId } = props;
  const dataProvider = useDataProvider();
  const [data, setdata] = useState([]);
  const [rows, setRows] = React.useState(false);
  const [authority, setAuthority] = React.useState(false);
  const [note, setNote] = React.useState(false);
  const [dbId, setDBid] = useState();
  const [current_revId, setcurrent_revId] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [isClicked, setIsCliked] = useState(false);

  const classes = useStyles();
  useEffect(() => {
    dataProvider.getvariations('tender-authority-condition', { filter: { project: projectId, revId: RevId, Prev_revId: Prev_revId }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      if (data && data.tender_authority_saved && data.tender_authority_saved.length > 0 && data.tender_authority_saved[0].revId !== RevId) {
        const new_obj = {
          Prev_revId: Prev_revId,
          revId: RevId,
          projectId: projectId,
        }
        dataProvider.newcreate1('tender-authority-note-array', { data: new_obj }).then(() => {
          dataProvider.newcreate1('tender-authority-condition-array', { data: new_obj }).then(({ data }) => {
            setLoading(false);
            setRows(data)

          })
            .catch(() => {
              setLoading(false);
            });
        })
          .catch(() => {
            setLoading(false);
          });
      }
      else {
        setRows(data)
        setLoading(false);
      }
    })
      .catch(() => {
        setLoading(false);
      });
  }, [isClicked]);

  const SingleCategoryViewNew = ({ item, basicList, type }) => {

    const savefunction = (change, estimateIndex) => {
      var new_object = {
        created_at: basicList[estimateIndex].created_at,
        dateAdded: basicList[estimateIndex].dateAdded,
        id: basicList[estimateIndex]._id,
        name: change,
        project: projectId,
        refId: basicList[estimateIndex].refId,
        revId: basicList[estimateIndex].revId,
        _id: basicList[estimateIndex]._id,
        type: type
      }
      dataProvider.updateEstimate('tender-authority-condition', new_object).then(({ data }) => {
        setRows(data)
      })
    }

    const Estimate_Delete = (subcat) => {
      var Newobject = {
        id: subcat?._id,
        project: projectId,
        revId: RevId,
        Prev_revId: Prev_revId,
        type: type
      }
      dataProvider.deleteEstimate('tender-authority-condition', Newobject).then(({ data }) => {
        setRows(data)
      })
        .catch(() => {
          setLoading(false);
        });
    }
    const Estimate_Save = (subcat) => {
      const new_obj =
      {
        name: subcat.name,
        project: projectId,
        revId: RevId,
        refId: subcat._id,
        Prev_revId: Prev_revId,
        type: type
      }
      dataProvider.newcreate1('tender-authority-condition', { data: new_obj }).then(({ data }) => {
        setRows(data)
      })
        .catch(() => {
          setLoading(false);
        });
    }
    if (item) {
      let mainIndex = basicList.findIndex(x => x.refId === item?._id);
      if (mainIndex >= 0) {
        return (
          <TableRow key={item?.id}>
            <TableCell ><input
              defaultValue={basicList[mainIndex].name}
              style={{ textAlign: 'left', borderWidth: 0, paddingBottom: 5, paddingTop: 5, width: '80%' }}
              onBlur={(e) => savefunction(e.target.value, mainIndex)}
              type="text"
              name="name" />
            </TableCell>
            <TableCell align={"right"}>
              <Checkbox
                checked={true}
                color="primary"
                onChange={(e) => Estimate_Delete(basicList[mainIndex], e)}
                sx={{
                  color: ' #ffcccc',
                  '&.Mui-checked': {
                    color: '#e60000',
                  },
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </TableCell>
          </TableRow>
        )

      }
    }
    return (
      <TableRow key={item?.id}>
        <TableCell >
          <input
            defaultValue={item.name}
            style={{ textAlign: 'left', borderWidth: 0, paddingBottom: 5, paddingTop: 5, width: '80%' }}
            // onBlur={(e) => savefunction(e.target.value, item.id)}
            type="text"
            name="name" />
        </TableCell>
        <TableCell align={"right"}>
          <Checkbox
            // checked={}
            color="primary"
            onChange={(e) => Estimate_Save(item, e)}
            sx={{
              color: ' #ffcccc',
              '&.Mui-checked': {
                color: '#e60000',
              },
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </TableCell>
      </TableRow>
    )
  }
  if (!rows && !authority && !note) return null;
  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer ref={ref} component={Paper} className={classes.tableContainer}>
          <Grid container >
            <Grid item xs={12} sm={9}>
              <SectionHeading label="Tender Authority Conditions" classProp={classes.heading} />
            </Grid>
          </Grid>
          <Table className={classes.table} size="small" aria-label="material-table">
            <TableBody>
              {rows && rows.tender_authority && rows.tender_authority.length > 0 && rows.tender_authority.map((item) => {
                return (
                  <SingleCategoryViewNew item={item} basicList={rows.tender_authority_saved} type={'authority'} />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer ref={ref} component={Paper} className={classes.tableContainer}>
          <Grid container >
            <Grid item xs={12} sm={9}>
              <SectionHeading label="Tender Authority Notes" classProp={classes.heading} />
            </Grid>
          </Grid>
          <Table className={classes.table} size="small" aria-label="material-table">
            <TableBody>
              {rows && rows.tender_authority_note && rows.tender_authority_note.length > 0 && rows.tender_authority_note.map((item) => {
                return (
                  <SingleCategoryViewNew item={item} basicList={rows.tender_authority_note_saved} type={'note'} />
                )
              })}
            </TableBody>
          </Table>

          <Button
            style={{ backgroundColor: '#e5aa13', color: 'white', float: 'right', right: 20, marginTop: 25 }}
            variant="outlined"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={() => setIsCliked(!isClicked)}
          >
            save
          </Button>
        </TableContainer>
      </Paper>
    </>
  )
}

const SectionHeading = ({ label, classProp }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classProp}>
      <ListIcon />
      <Typography variant="h6" style={{ marginTop: 5 }} gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};


const useStyles = makeStyles({
  tableContainer: {
    marginTop: 15,
    paddingBottom: 15,
    boxShadow: 'none',
    border: '1px solid #ebebeb',
    width: 'auto',
    marginLeft: 10,
  },
  heading: {
    padding: 10,
    '& h6': {
      fontSize: 14,
      paddingLeft: 5,
      fontWeight: 'bold',
      letterSpacing: 1,
      color: '#e58f0a',
      textTransform: 'uppercase',
    }
  },
  table: {
    minWidth: 650,
  },
  deleteButton: {
    border: '1px solid #ff0000', color: '#ff0000', marginLeft: 15,
  },
  titlePaper: {
    background: '#f3f3f3',
    marginTop: 20,
    boxShadow: 'none',
  },
  projectTitle: {
    background: '#282a3c',
    color: '#fff',
    padding: 10,
    fontSize: 15,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
  },
});


export default TenderBasicList;
