import React, { useEffect, useState } from 'react';
import {
  useDataProvider,
  SelectInput,
  SimpleForm, FileInput, FileField, Loading
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Box, Typography, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import GetApp from '@material-ui/icons/GetApp';
import { usePermissions } from 'react-admin';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import FolderIcon from '@material-ui/icons/Folder';
import { useHistory } from "react-router-dom";
import ProjectCertificates from './ProjectCertificates';

const queryString = require('query-string');

const useStyles = makeStyles((theme) => ({
  heading: {
    marginTop: 10,
    marginBottom: 10,
    background: '#fff',
    padding: '10px 10px',
    border: '1px solid #f5f0f0',
  },
  addButton: {
    marginLeft: 20,
  },
  uploadForm: {
    border: '1px dashed orange',
    margin: '20px 0',
  },
  fileHolder: {
    //background: '#efefef',
    // justifyContent: 'center',
    alignItems: 'center',
  },
  holderBox: {
    // border: '1px solid #e9e9e9',
    background: '#fff',
    textAlign: 'center',
    '& a': {
      textDecoration: 'none',
      color: '#514646',
    },
    margin: 5,
    position: 'relative',
    paddingTop: 45,
  },
  fileDisplay: {
    textAlign: 'center',
    '& svg': {
      color: '#2b6b88',
      fontSize: '100px',
    }
  },
  fileTitle: {
    fontSize: 13,
    fontWeight: 'bold',
    padding: '5px 0',
  },
  folderTitle: {
    fontSize: 11,
    fontWeight: 'bold',
    padding: '5px 0',
    textTransform: 'uppercase',
  },
  fileSubTitle: {
    fontSize: 12,
    color: '#322f2f',
  },
  actionButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  buttonIcon: {
    padding: 5,
  },
  removeButton: {
    //color: '#ef5d5d',
  },
  downloadButton: {
    //color: '#597bc6',
  },
  folderHolder: {
    textAlign: 'center',
    background: '#ffff',
    border: '1px solid #f1f1f1',
    borderRadius: 10,
    cursor: 'pointer',
    padding: '10px 20px',
    '&:hover': {
      background: '#fff9ef',
    }
  },
  capitalizeTxt: {
    textTransform: 'capitalize'
  }
}));

function Projectfiles({ projectId, match, location }) {

  const parsedQuery = queryString.parse(location.search);
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState(
    {
      general_valut: [],
      client_valut: [],
      contractor_valut: [],
      tender_valut: []
    }
  );
  const [oldFiles, setOldFiles] = useState({
    general: [],
    client: [],
    contractor: [],
    tender: []
  });
  const [revisions, setRevisions] = useState([]);
  const [loadFiles, setLoadFiles] = useState([]);
  const [openUpload, setOpenUpload] = useState(false);
  const { permissions } = usePermissions();
  const [dialogStat, setDialogStat] = useState({ visible: false, file: null });
  const history = useHistory();

  const groupFiles = (data) => {
    if (data && data.length > 0) {
      let genFilesCategories = [];
      let clientFilesCategories = [];
      let contractorFilesCategories = [];
      let tenderFilesCategories = [];
      let clientFiles = [];
      let contractorFiles = [];
      let tenderFiles = [];
      let genFiles = [];
      data && data.length > 0 && data.map(file => {

        if (file?.category === 'client') {
          if (file?.sub_category) {
            clientFilesCategories.push(file.sub_category);
          } else {
            clientFiles.push(file);
          }
        } else if (file?.category === 'contractor') {
          if (file.sub_category) {
            contractorFilesCategories.push(file.sub_category)
          } else {
            contractorFiles.push(file)
          }
        } else if (file?.category === 'tender') {
          if (file?.sub_category) {
            tenderFilesCategories.push(file.sub_category);
          } else {
            tenderFiles.push(file)
          }
        } else {
          if (file?.sub_category) {
            genFilesCategories.push(file.sub_category);
          } else {
            genFiles.push(file)
          }
        }
      })
      setOldFiles({
        general: genFiles,
        client: clientFiles,
        contractor: contractorFiles,
        tender: tenderFiles,
      });
      setFiles({
        general_valut: [...new Set(genFilesCategories)],
        client_valut: [...new Set(clientFilesCategories)],
        contractor_valut: [...new Set(contractorFilesCategories)],
        tender_valut: [...new Set(tenderFilesCategories)],
      });
    }
  }

  useEffect(() => {
    dataProvider.getProjectFiles(projectId).then(({ data }) => {
      groupFiles(data);
      setLoadFiles(data);
      let main_cat = parsedQuery.category;
      if (parsedQuery.category === 'general') {
        main_cat = null
      }
      let revisions = data.map(item => {
        if (item?.category === main_cat && item?.sub_category === parsedQuery.sub_category) return item.revision
      })
      setRevisions([...new Set(revisions)]);
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
  }, [parsedQuery.sub_category]);

  const uploadFile = (data) => {
    if (data.files && data.files !== undefined && data.files.length > 0) {
      setLoading(true);
      if (parsedQuery && parsedQuery.category) {
        data.category = parsedQuery.category;
      } else {
        data.category = '';
      }
      dataProvider.uploadprojectImages(projectId, data).then(({ data }) => {
        groupFiles(data);
        setLoadFiles(data);
        setLoading(false);
      })
        .catch(error => {
          setLoading(false);
        })
    }
  }
  const setCategory = (category) => {
    history.push(`/project-gallery/${projectId}?category=${category}`);
  };
  const setSubCategory = (category, sub_cat) => {
    history.push(`/project-gallery/${projectId}?category=${category}&sub_category=${sub_cat}`);
  };
  const setRevision = (category, sub_cat, rev) => {
    history.push(`/project-gallery/${projectId}?category=${category}&sub_category=${sub_cat}&rev=${rev}`);
  };
  const downloadFile = (file) => {
    fetch(file.file_location, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", file.name);
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });
  }
  const openDialog = (fileInfo) => {
    setDialogStat({ visible: true, file: fileInfo })
  }
  const closeDialog = () => {
    setDialogStat({ visible: false, file: null });
  }
  const setOldCategory = (category) => {
    history.push(`/project-gallery/${projectId}?category=${category}&sub_category=0`);
  };
  const removeFile = (fileInfo) => {
    closeDialog();
    setLoading(true);
    dataProvider.delete('projectfiles', { id: fileInfo.id }).then(({ data }) => {
      let filesArray = loadFiles.filter(file => file.id !== fileInfo.id);
      setLoadFiles(filesArray);
      setLoading(false);
    })
      .catch(err => {
        console.log(err);
        setLoading(false);
      })
  }

  const classes = useStyles();

  if (loading) return <Loading loadingSecondary="" />;

  return (
    <>
      <Container>
        {parsedQuery?.category && parsedQuery?.category !== 'certificate' && !parsedQuery?.sub_category && (
          <>
            <Grid container direction="row" alignItems="center" className={classes.heading}>
              <Typography variant="h6" gutterBottom className={classes.capitalizeTxt}>
                {`${parsedQuery?.category} Files`}
              </Typography>
              {(permissions && (permissions.role === "admin" || permissions.role === "manager") && (
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.addButton}
                  onClick={() => setOpenUpload((prevStatus) => !prevStatus)}
                >
                  Add New
                </Button>
              ))}
            </Grid>
            {(openUpload &&
              (
                <SimpleForm save={uploadFile} className={classes.uploadForm}>
                  <SelectInput
                    label="File Category"
                    source="file_category"
                    fullWidth={true}
                    variant="outlined"
                    defaultValue="Other"
                    choices={[
                      { id: 'Other', name: 'Other' },
                      { id: 'Electrical', name: 'Electrical' },
                      { id: 'Plumbing', name: 'Plumbing' },
                      { id: 'Roofing', name: 'Roofing' },
                      { id: 'Carpentary', name: 'Carpentary' },
                      { id: 'Welding', name: 'Welding' },
                    ]}
                  />
                  <FileInput source="files" label="Related files" accept="" multiple={true}>
                    <FileField source="src" title="title" />
                  </FileInput>
                </SimpleForm>
              )
            )}
          </>
        )}
        <Box
          display="flex"
          flexWrap="wrap"
          className={classes.fileHolder}
        >
          {(!parsedQuery.sub_category && parsedQuery.category) ? (

            <>
              {parsedQuery.category === 'general' && (
                <Box
                  display="flex"
                  flexWrap="wrap"
                  className={classes.fileHolder}
                >

                  {files?.general_valut?.map((item, index) => {
                    return (
                      <Box
                        p={1} m={2}
                        alignItems="center"
                        className={classes.folderHolder}
                        onClick={() => setSubCategory('general', item)}
                      >
                        <div className={classes.fileIcon}>
                          <FolderIcon fontSize="large" style={{ color: '#ffbb54', width: 100, height: 100 }} />
                        </div>
                        <Typography className={classes.folderTitle}>{item}</Typography>
                      </Box>
                    )

                  })}
                </Box>
              )}
              {parsedQuery.category === 'client' && (
                <Box
                  display="flex"
                  flexWrap="wrap"
                  className={classes.fileHolder}
                >

                  {files?.client_valut?.map((item, index) => {
                    return (
                      <Box
                        p={1} m={2}
                        alignItems="center"
                        className={classes.folderHolder}
                        onClick={() => setSubCategory('client', item)}
                      >
                        <div className={classes.fileIcon}>
                          <FolderIcon fontSize="large" style={{ color: '#ffbb54', width: 100, height: 100 }} />
                        </div>
                        <Typography className={classes.folderTitle}>{item}</Typography>
                      </Box>
                    )

                  })}
                </Box>
              )}
              {parsedQuery.category === 'contractor' && (
                <Box
                  display="flex"
                  flexWrap="wrap"
                  className={classes.fileHolder}
                >

                  {files?.contractor_valut?.map((item, index) => {
                    return (
                      <Box
                        p={1} m={2}
                        alignItems="center"
                        className={classes.folderHolder}
                        onClick={() => setSubCategory('contractor', item)}
                      >
                        <div className={classes.fileIcon}>
                          <FolderIcon fontSize="large" style={{ color: '#ffbb54', width: 100, height: 100 }} />
                        </div>
                        <Typography className={classes.folderTitle}>{item}</Typography>
                      </Box>
                    )

                  })}
                </Box>
              )}
              {parsedQuery.category === 'tender' && (
                <Box
                  display="flex"
                  flexWrap="wrap"
                  className={classes.fileHolder}
                >

                  {files?.tender_valut?.map((item, index) => {
                    return (
                      <Box
                        p={1} m={2}
                        alignItems="center"
                        className={classes.folderHolder}
                        onClick={() => setSubCategory('tender', item)}
                      >
                        <div className={classes.fileIcon}>
                          <FolderIcon fontSize="large" style={{ color: '#ffbb54', width: 100, height: 100 }} />
                        </div>
                        <Typography className={classes.folderTitle}>{item}</Typography>
                      </Box>
                    )

                  })}
                </Box>
              )}
              {parsedQuery.category === 'certificate' && (
                <>
                  <Grid container direction="row" alignItems="center" className={classes.heading}>
                    <Typography variant="h6" gutterBottom className={classes.capitalizeTxt}>
                      Certificates
                    </Typography>
                  </Grid>
                  <ProjectCertificates project={projectId} />
                </>
              )}
            </>

          ) : (
            <>
              {(parsedQuery.sub_category && parsedQuery.sub_category != 0) && !parsedQuery.rev && (
                <>
                  <Grid container direction="row" alignItems="center" className={classes.heading}>
                    <Typography variant="h6" gutterBottom className={classes.capitalizeTxt}>
                      {`${parsedQuery?.category} Files`}
                      <span style={{ color: '#e5aa13' }}>&#12297;</span>
                      {`${parsedQuery.sub_category}`}
                    </Typography>
                  </Grid>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    className={classes.fileHolder}
                  >
                    {/* {files?.general_valut?.revisions?.sub_category === parsedQuery.sub_category && ( */}
                    <>
                      {revisions?.map((item) => {
                        if (typeof item === 'undefined') return;
                        return (
                          <Box
                            p={1} m={2}
                            alignItems="center"
                            className={classes.folderHolder}
                            onClick={() => setRevision(parsedQuery.category, parsedQuery.sub_category, item)}
                          >
                            <div className={classes.fileIcon}>
                              <FolderIcon fontSize="large" style={{ color: '#ffbb54', width: 100, height: 100 }} />
                            </div>
                            <Typography className={classes.folderTitle}> Revision {item}</Typography>
                          </Box>
                        )

                      })}
                    </>
                    {/* )} */}

                  </Box>
                </>
              )}
            </>
          )}
          {parsedQuery.rev && (
            <>
              <Grid container direction="row" alignItems="center" className={classes.heading}>
                <Typography variant="h6" gutterBottom className={classes.capitalizeTxt}>
                  {`${parsedQuery?.category} Files`} <span style={{ color: '#e5aa13' }}>&#12297;</span> {`${parsedQuery.sub_category}`} <span style={{ color: '#e5aa13' }}>&#12297;</span> {`Revision ${parsedQuery.rev}`}
                </Typography>
              </Grid>
              {loadFiles?.length > 0 &&
                loadFiles
                  .filter(file => (file.category === (parsedQuery.category === 'general' ? null : parsedQuery.category) && file.sub_category === parsedQuery.sub_category && file.revision == parsedQuery.rev))
                  .map((file) => (
                    <Box
                      p={1}
                      m={2}
                      alignItems="center"
                      key={file._id}
                      className={classes.holderBox}
                    //onClick={() => downloadFile(file)}
                    >
                      <div className={classes.actionButton}>
                        <IconButton
                          aria-label="download"
                          className={[classes.buttonIcon, classes.downloadButton]}
                          onClick={() => downloadFile(file)}
                        >
                          <GetApp />
                        </IconButton>
                        {(permissions && (permissions.role === "admin") && (
                          <IconButton
                            aria-label="delete"
                            className={[classes.buttonIcon, classes.removeButton]}
                            onClick={() => openDialog(file)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        ))}
                      </div>
                      <FilePreview file={file} />
                    </Box>
                  ))}
            </>
          )}
          {parsedQuery.category && parsedQuery.sub_category == 0 && (
            <>
              <Grid container direction="row" alignItems="center" className={classes.heading}>
                <Typography variant="h6" gutterBottom className={classes.capitalizeTxt}>
                  {`${parsedQuery.category} Files`}
                </Typography>
              </Grid>
              {parsedQuery && parsedQuery.category === "general" && (
                <>
                  {oldFiles.general.map((file) => (
                    <Box
                      p={1}
                      m={2}
                      alignItems="center"
                      key={file._id}
                      className={classes.holderBox}
                    //onClick={() => downloadFile(file)}
                    >
                      <div className={classes.actionButton}>
                        <IconButton
                          aria-label="download"
                          className={[classes.buttonIcon, classes.downloadButton]}
                          onClick={() => downloadFile(file)}
                        >
                          <GetApp />
                        </IconButton>
                        {(permissions && (permissions.role === "admin") && (
                          <IconButton
                            aria-label="delete"
                            className={[classes.buttonIcon, classes.removeButton]}
                            onClick={() => openDialog(file)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        ))}
                      </div>
                      <FilePreview file={file} />
                    </Box>
                  ))}
                </>
              )}

              {parsedQuery && parsedQuery.category === "client" && (
                <>
                  {oldFiles.client.map((file) => (
                    <Box
                      p={1}
                      m={2}
                      alignItems="center"
                      key={file._id}
                      className={classes.holderBox}
                    //onClick={() => downloadFile(file)}
                    >
                      <div className={classes.actionButton}>
                        <IconButton
                          aria-label="download"
                          className={[classes.buttonIcon, classes.downloadButton]}
                          onClick={() => downloadFile(file)}
                        >
                          <GetApp />
                        </IconButton>
                        {(permissions && (permissions.role === "admin") && (
                          <IconButton
                            aria-label="delete"
                            className={[classes.buttonIcon, classes.removeButton]}
                            onClick={() => openDialog(file)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        ))}
                      </div>
                      <FilePreview file={file} />
                    </Box>
                  ))}
                </>
              )}

              {parsedQuery && parsedQuery.category === "contractor" && (
                <>
                  {oldFiles.contractor.map((file) => (
                    <Box
                      p={1}
                      m={2}
                      alignItems="center"
                      key={file._id}
                      className={classes.holderBox}
                    //onClick={() => downloadFile(file)}
                    >
                      <div className={classes.actionButton}>
                        <IconButton
                          aria-label="download"
                          className={[classes.buttonIcon, classes.downloadButton]}
                          onClick={() => downloadFile(file)}
                        >
                          <GetApp />
                        </IconButton>
                        {(permissions && (permissions.role === "admin") && (
                          <IconButton
                            aria-label="delete"
                            className={[classes.buttonIcon, classes.removeButton]}
                            onClick={() => openDialog(file)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        ))}
                      </div>
                      <FilePreview file={file} />
                    </Box>
                  ))}
                </>
              )}
              {parsedQuery && parsedQuery.category === "tender" && (
                <>
                  {oldFiles.tender.map((file) => (
                    <Box
                      p={1}
                      m={2}
                      alignItems="center"
                      key={file._id}
                      className={classes.holderBox}
                    //onClick={() => downloadFile(file)}
                    >
                      <div className={classes.actionButton}>
                        <IconButton
                          aria-label="download"
                          className={[classes.buttonIcon, classes.downloadButton]}
                          onClick={() => downloadFile(file)}
                        >
                          <GetApp />
                        </IconButton>
                        {(permissions && (permissions.role === "admin") && (
                          <IconButton
                            aria-label="delete"
                            className={[classes.buttonIcon, classes.removeButton]}
                            onClick={() => openDialog(file)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        ))}
                      </div>
                      <FilePreview file={file} />
                    </Box>
                  ))}
                </>
              )}
              {parsedQuery && parsedQuery.category === "certificate" && (
                <>
                  <Grid container direction="row" alignItems="center" className={classes.heading}>
                    <Typography variant="h6" gutterBottom className={classes.capitalizeTxt}>
                      Certificates
                    </Typography>
                  </Grid>
                  <ProjectCertificates project={projectId} />
                </>
              )}
            </>
          )}
          {(!parsedQuery.category) && (
            <>
              <Grid container direction="row" alignItems="center" className={classes.heading}>
                <Typography variant="h6" gutterBottom>
                  Project Gallery
                </Typography>
              </Grid>
              <Box
                display="flex"
                flexWrap="wrap"
                className={classes.fileHolder}
              >
                <Box
                  p={1} m={2}
                  alignItems="center"
                  className={classes.folderHolder}
                  onClick={() => setCategory('general')}
                >
                  <div className={classes.fileIcon}>
                    <FolderIcon fontSize="large" style={{ color: '#ffbb54', width: 100, height: 100 }} />
                  </div>
                  <Typography className={classes.folderTitle}>General</Typography>
                </Box>

                {(permissions && (permissions.role === "admin" || permissions.role === "manager" || permissions.role === "client")) && (
                  <Box
                    p={1} m={2}
                    alignItems="center"
                    className={classes.folderHolder}
                    onClick={() => setCategory('client')}
                  >
                    <div className={classes.fileIcon}>
                      <FolderIcon fontSize="large" style={{ color: '#ffbb54', width: 100, height: 100 }} />
                    </div>

                    <Typography className={classes.folderTitle}>Client</Typography>
                  </Box>
                )}

                {(permissions && (permissions.role === "admin" || permissions.role === "manager" || permissions.role === "contractor")) && (
                  <Box
                    p={1} m={2}
                    alignItems="center"
                    className={classes.folderHolder}
                    onClick={() => setCategory('contractor')}
                  >
                    <div className={classes.fileIcon}>
                      <FolderIcon fontSize="large" style={{ color: '#ffbb54', width: 100, height: 100 }} />
                    </div>
                    <Typography className={classes.folderTitle}>Contractor</Typography>
                  </Box>
                )}
                {(permissions && (permissions.role === "admin" || permissions.role === "manager" || permissions.role === "contractor")) && (
                  <Box
                    p={1} m={2}
                    alignItems="center"
                    className={classes.folderHolder}
                    onClick={() => setCategory('tender')}
                  >
                    <div className={classes.fileIcon}>
                      <FolderIcon fontSize="large" style={{ color: '#ffbb54', width: 100, height: 100 }} />
                    </div>
                    <Typography className={classes.folderTitle}>Tender files</Typography>
                  </Box>
                )}
                <Box
                  p={1} m={2}
                  alignItems="center"
                  className={classes.folderHolder}
                  onClick={() => setCategory('certificate')}
                >
                  <div className={classes.fileIcon}>
                    <FolderIcon fontSize="large" style={{ color: '#ffbb54', width: 100, height: 100 }} />
                  </div>
                  <Typography className={classes.folderTitle}>Certificates</Typography>
                </Box>

              </Box>
              {(oldFiles?.general?.length > 0 || oldFiles?.client?.length > 0 || oldFiles?.contractor?.length > 0 || oldFiles?.tender?.length > 0) && (
                <>
                  <Grid container direction="row" alignItems="center" className={classes.heading}>
                    <Typography variant="h6" gutterBottom>
                      Old Project Gallery
                    </Typography>
                  </Grid>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    className={classes.fileHolder}
                  >
                    <Box
                      p={1}
                      m={2}
                      alignItems="center"
                      className={classes.folderHolder}
                      onClick={() => setOldCategory('general')}
                    >
                      <div className={classes.fileIcon}>
                        <FolderIcon fontSize="large" style={{ color: '#ffbb54', width: 100, height: 100 }} />
                      </div>
                      <Typography className={classes.folderTitle}>General</Typography>
                    </Box>
                    {(permissions && (permissions.role === "admin" || permissions.role === "manager" || permissions.role === "client")) && (
                      <Box
                        p={1} m={2}
                        alignItems="center"
                        className={classes.folderHolder}
                        onClick={() => setOldCategory('client')}
                      >
                        <div className={classes.fileIcon}>
                          <FolderIcon fontSize="large" style={{ color: '#ffbb54', width: 100, height: 100 }} />
                        </div>

                        <Typography className={classes.folderTitle}>Client</Typography>
                      </Box>
                    )}
                    {(permissions && (permissions.role === "admin" || permissions.role === "manager" || permissions.role === "contractor")) && (
                      <Box
                        p={1} m={2}
                        alignItems="center"
                        className={classes.folderHolder}
                        onClick={() => setOldCategory('contractor')}
                      >
                        <div className={classes.fileIcon}>
                          <FolderIcon fontSize="large" style={{ color: '#ffbb54', width: 100, height: 100 }} />
                        </div>
                        <Typography className={classes.folderTitle}>Contractor</Typography>
                      </Box>
                    )}
                    {(permissions && (permissions.role === "admin" || permissions.role === "manager" || permissions.role === "contractor")) && (
                      <Box
                        p={1} m={2}
                        alignItems="center"
                        className={classes.folderHolder}
                        onClick={() => setOldCategory('tender')}
                      >
                        <div className={classes.fileIcon}>
                          <FolderIcon fontSize="large" style={{ color: '#ffbb54', width: 100, height: 100 }} />
                        </div>
                        <Typography className={classes.folderTitle}>Tender files</Typography>
                      </Box>
                    )}
                    <Box
                      p={1} m={2}
                      alignItems="center"
                      className={classes.folderHolder}
                      onClick={() => setCategory('certificate')}
                    >
                      <div className={classes.fileIcon}>
                        <FolderIcon fontSize="large" style={{ color: '#ffbb54', width: 100, height: 100 }} />
                      </div>
                      <Typography className={classes.folderTitle}>Certificates</Typography>
                    </Box>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      </Container>
      <Dialog
        open={dialogStat.visible}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure want to delete file ${(dialogStat.file && dialogStat.file.name) ? dialogStat.file.name : ''}`}</DialogTitle>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            No
          </Button>
          <Button color="primary" onClick={() => removeFile(dialogStat.file)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const FilePreview = ({ file }) => {
  const classes = useStyles();
  if (file.type.search('image') >= 0) {
    return (
      <a href={file.file_location} target="_blank" rel="noopener noreferrer">
        <img
          style={{
            width: 150,
            height: 'auto',
            maxHeight: 130,
          }}
          src={file.file_location}
          alt=""
        />
        {(file.name) && (
          <Typography className={classes.fileTitle}>{file.name}</Typography>
        )}
        {(file.stat) && (
          <Typography className={classes.fileSubTitle}>{`Uploaded On ${file.stat}`}</Typography>
        )}
      </a>
    )
  } else if (file.type === 'application/pdf') {
    return (
      <div className={classes.fileIcon}>
        <a href={file.file_location} target="_blank" rel="noopener noreferrer">
          <PictureAsPdfIcon fontSize="large" style={{ color: 'red', width: 130, height: 100 }} />
          <Typography className={classes.fileTitle}>{file.name}</Typography>
          {(file.stat) && (
            <Typography className={classes.fileSubTitle}>{`Uploaded On ${file.stat}`}</Typography>
          )}
        </a>
      </div>
    )
  } else if (file.type.search('video') >= 0) {
    return (
      <div className={classes.fileIcon}>
        <a href={file.file_location} target="_blank" rel="noopener noreferrer">
          <PlayCircleFilledIcon fontSize="large" style={{ color: '#6666fd', width: 130, height: 100 }} />
          <Typography className={classes.fileTitle}>{file.name}</Typography>
          {(file.stat) && (
            <Typography className={classes.fileSubTitle}>{`Uploaded On ${file.stat}`}</Typography>
          )}
        </a>
      </div>
    )
  } else {
    return (
      <a href={file.file_location} target="_blank" rel="noopener noreferrer">
        {(file.name) && (
          <Typography className={classes.fileTitle}>{file.name}</Typography>
        )}
        {(file.stat) && (
          <Typography className={classes.fileSubTitle}>{`Uploaded On ${file.stat}`}</Typography>
        )}
      </a>
    )
  }
}

export default Projectfiles;
