import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import ApiConfig from './config/apiconfig';

const apiUrl = ApiConfig.REST_API_URL;
const authUrl = ApiConfig.REST_API_AUTH_URL;
// const httpClient = fetchUtils.fetchJson;

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  //const userInfo = JSON.parse(localStorage.getItem('nexa_pm_user'));
  var token = localStorage.getItem('nexa_pm_user_token');

  if (token) {
    options.headers.set('Authorization', `Bearer ${token}`);
  }
  return fetchUtils.fetchJson(url, options);
};

export default {

  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const order = params.sort.order.toLowerCase();
    const field = (params.sort.field === 'id') ? '_id' : params.sort.field;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get('x-total-count'), 10),
    }));
    //parseInt(headers.get('content-range').split('/').pop(), 10)
  },
  getOne: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    }))
  },


  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get('content-range').split('/').pop(), 10),
    }));
  },

  update: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
  newupadte: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
  newcreate: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }))
  },
  newcreate1: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: json,
    }))
  },
  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }))
  },

  delete: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }));
  },

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  /* for getting contactors for task */

  getTaskContractors: (id) => {
    const url = `${apiUrl}/taskinstance/${id}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
    }));
  },

  /* for updating contactors for task */

  updateTaskContractors: (id, params) => {
    return httpClient(`${apiUrl}/taskinstance/${id}`, {
      method: 'PUT',
      body: JSON.stringify(params),
    }).then(({ json }) => ({ data: json }))
  },

  /*for creating task */
  createTask: (resource, params) => {
    var formData = new FormData();
    if (params.files && params.files !== undefined && params.files.rawFile) {
      // console.log(params.files.rawFile);
      formData.append('uploadFile', params.files.rawFile);
    }
    // if(params.files && params.files.length > 0) {
    //     let fileList = params.files.map(file => {
    //       return file.rawFile;
    //     });
    //     formData.append('uploadFiles',  params.files);
    //   }

    formData.append('predecessor', JSON.stringify(params.predecessor));

    for (var key in params) {
      if (typeof (params[key]) !== 'object') {
        formData.append(key, params[key]);
      }
    }
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: formData,
    }).then(({ json }) => ({
      data: { ...params },
    }))
  },

  /* for creating Project */

  createproject: (resource, params) => {
    var formData = new FormData();
    if (params.client.image && params.client.image !== undefined && params.client.image.rawFile) {
      formData.append('profileImg', params.client.image.rawFile);
    }
    if (params.facade && params.facade !== undefined && params.facade.rawFile) {
      formData.append('facade', params.facade.rawFile);
    }
    formData.append('client', JSON.stringify(params.client));
    formData.append('managers', JSON.stringify(params.managers));
    formData.append('contractors', JSON.stringify(params.contractors));
    formData.append('secondary_clients', JSON.stringify(params.secondary_clients));
    for (var key in params) {
      if (typeof (params[key]) !== 'object') {
        formData.append(key, params[key]);
      }
    }
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: formData,
    }).then(({ json }) => ({
      data: { ...params },
    }))
  },

  updateProject: (resource, params) => {
    var formData = new FormData();
    for (var key in params) {
      if (typeof (params[key]) !== 'object') {
        formData.append(key, params[key]);
      }
    }
    formData.append('managers', JSON.stringify(params.managers));
    formData.append('contractors', JSON.stringify(params.contractors));
    formData.append('secondary_clients', JSON.stringify(params.secondary_clients));
    if (params.image && params.image !== undefined && params.image.rawFile) {
      formData.append('profileImg', params.image.rawFile);
    }
    if (params.facade && params.facade !== undefined && params.facade.rawFile) {
      formData.append('facade', params.facade.rawFile);
    }
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: formData,
    }).then(({ json }) => ({
      data: { ...params },
    }))
  },


  /* update task order */

  updatetasksort: (params) => {
    return httpClient(`${apiUrl}/updatetasksort`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },


  uploadprojectImages: (id, params) => {
    var formData = new FormData();
    formData.append('projectId', id);
    formData.append('category', params.category);
    formData.append('sub_category', params.file_category);
    if (params.files && params.files !== undefined && params.files.length > 0) {
      params.files.forEach(file => {
        if (file && file.rawFile) {
          formData.append('projectfile', file.rawFile);
        }
      });
    }
    return httpClient(`${apiUrl}/projectfiles`, {
      method: 'POST',
      body: formData,
    }).then(({ json }) => ({
      data: json,
    }))
  },

  getProjectFiles: (id) => {
    const url = `${apiUrl}/projectfiles/${id}`;

    return httpClient(url).then(({ headers, json }) => {
      return ({
        data: json,
      })
    });
  },

  getScheduletasks: (filter) => {

    let assigneeFilter = {};
    if (filter.assigneeId) {
      assigneeFilter.assignees = filter.assigneeId;
    }
    const query = {
      filter: JSON.stringify(assigneeFilter),
    };
    const url = `${apiUrl}/Schedules?${stringify(query)}`;
    return httpClient(url).then(({ headers, json }) => ({
      data: json,
    }));
  },

  getUpComingtasks: (filter) => {

    let assigneeFilter = {};
    if (filter.assigneeId) {
      assigneeFilter.assignees = filter.assigneeId;
    }
    const query = {
      filter: JSON.stringify(assigneeFilter),
    };
    const url = `${apiUrl}/upcomingSchedules?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
    }));
  },

  updateTaskStatus: (params) =>
    httpClient(`${apiUrl}/updateTaskComplete`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })
    ),

  getProjectSchedules: (params) => {
    let assigneeFilter = {};

    if (params.assigneeId) {
      assigneeFilter.assignees = params.assigneeId
    }
    const query = {
      filter: JSON.stringify(assigneeFilter),
    };
    const url = `${apiUrl}/Schedules/${params.id}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
    }));
  },

  getProjectUpComingSchedules: (params) => {
    let assigneeFilter = {};

    if (params.assigneeId) {
      assigneeFilter.assignees = params.assigneeId
    }
    const query = {
      filter: JSON.stringify(assigneeFilter),
    };
    const url = `${apiUrl}/upcomingSchedules/${params.id}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
    }));
  },

  updateprojectTaskStatus: (params) =>
    httpClient(`${apiUrl}/updateProjectTaskComplete`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })
    ),

  /* for getting project whole details */

  getProjectDetailInstance: (id) => {
    const url = `${apiUrl}/projectinfo/${id}`;

    return httpClient(url).then(({ headers, json }) => {
      return ({
        data: json,
      })
    });
  },
  getvariations: (resource, params) => {
    const { page, perPage } = params.pagination;
    const order = params.sort.order.toLowerCase();
    const field = (params.sort.field === 'id') ? '_id' : params.sort.field;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get('x-total-count'), 10),
    }));
    //parseInt(headers.get('content-range').split('/').pop(), 10)
  },
  createvariations: (resource, params) => {
    const { page, perPage } = params.pagination;
    const order = params.sort.order.toLowerCase();
    const field = (params.sort.field === 'id') ? '_id' : params.sort.field;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get('x-total-count'), 10),
    }));
    //parseInt(headers.get('content-range').split('/').pop(), 10)
  },
  updateProjectTask: (taskId, params) => {

    const url = `${apiUrl}/projecttask/${taskId}`;

    var formData = new FormData();
    //let uploadList = [];
    let permissionList = [];

    if (params.data.files && params.data.files !== undefined) {
      params.data.files.forEach(file => {
        permissionList.push({
          name: (file.name) ? file.name : file.rawFile.name,
          permissions: file.permissions,
        });
        if (file && file.rawFile) {
          formData.append('uploadFile', file.rawFile);
        } else if (file && !file.rawFile) {
          //uploadList.push(file);
        }
      });
    }
    // if(params.data.uploads && params.data.uploads !== undefined) {
    //   params.data.uploads.forEach(upload => {
    //     permissionList.push({
    //       id: upload.id,
    //       name: upload.name,
    //       permissions: upload.permissions,
    //     });
    //   });
    // }

    formData.append('permissionList', JSON.stringify(permissionList));
    formData.append('uploads', JSON.stringify(params.data.uploads));
    //formData.append('uploads', JSON.stringify(params.data.uploads));
    formData.append('assignees', JSON.stringify(params.data.assignees));
    formData.append('predecessor', JSON.stringify(params.data.predecessor));
    formData.append('contractors', JSON.stringify(params.data.contractors));
    formData.append('completedBy', JSON.stringify(params.data.completedBy));
    //formData.append('end_date', JSON.stringify(params.data.end_date));
    for (var key in params.data) {
      if (typeof (params.data[key]) !== 'object') {
        formData.append(key, params.data[key]);
      }
    }
    return httpClient(url, {
      method: 'PUT',
      body: formData,
    }).then(({ json }) => ({ data: json }));
  },
  createProjectLink: (params) => {
    const url = `${apiUrl}/projectlink`;
    return httpClient(url, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
  /* for deleting project Links */
  deleteProjectLink: (linkId, params) => {
    const url = `${apiUrl}/projectlink/${linkId}`;
    return httpClient(url, {
      method: 'DELETE',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
  setStartDateFromPredecessor: (params) =>
    httpClient(`${apiUrl}/updateStartDate`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })
    ),

  setLagFromStartDate: (params) =>
    httpClient(`${apiUrl}/updateLag`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })
    ),

  setEndDateFromDuration: (params) =>
    httpClient(`${apiUrl}/updateEndDate`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })
    ),

  setDurationFromEndDate: (params) =>
    httpClient(`${apiUrl}/updateDuration`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })
    ),

  // updateProjectTasks: (id, params) => {
  //   const url = `${apiUrl}/projecttasks/${id}`;
  //   return httpClient(url, {
  //     method: 'PUT',
  //     body: JSON.stringify(params.data),
  //   }).then(({ json }) => ({ data: json }));
  // },

  createProjectinfo: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  deleteProjectinfo: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
      body: JSON.stringify(params),
    }).then(({ json }) => ({ data: json }));
  },
  getProjectsOfContractor: (params) => {
    return httpClient(`${apiUrl}/projectsofcontractor`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  updateProjectTodo: (resource, params) => {
    var formData = new FormData();
    let uploadList = [];
    if (params.files && params.files !== undefined) {
      params.files.forEach(file => {
        if (file && file.rawFile) {
          formData.append('uploadFile', file.rawFile);
        } else if (file && !file.rawFile) {
          uploadList.push(file);
        }
      });
    }
    formData.append('uploads', JSON.stringify(uploadList));
    formData.append('assignees', JSON.stringify(params.assignees));
    for (var key in params) {
      if (typeof (params[key]) !== 'object') {
        formData.append(key, params[key]);
      }
    }
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: formData,
    }).then(({ json }) => ({ data: json }));
  },
  getSettings: () =>
    httpClient(`${apiUrl}/settings`).then(({ json }) => ({
      data: json,
    })),

  getMessages: (params) =>
    httpClient(`${apiUrl}/task-messages/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  saveMessage: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: json,
    }))
  },

  createProjectTask: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: json,
    }))
  },
  updateProjectTaskContractor: (taskId, params) => {

    const url = `${apiUrl}/projecttask-contractor/${taskId}`;

    var formData = new FormData();
    // let uploadList = [];

    if (params.data.files && params.data.files !== undefined) {
      params.data.files.forEach(file => {
        if (file && file.rawFile) {
          formData.append('uploadFile', file.rawFile);
        } else if (file && !file.rawFile) {
          //uploadList.push(file);
        }
      });
    }

    //formData.append('uploads', JSON.stringify(uploadList));
    formData.append('uploads', JSON.stringify(params.data.uploads));
    formData.append('all_notes', params.data.all_notes);
    formData.append('sub_notes', params.data.sub_notes);

    return httpClient(url, {
      method: 'PUT',
      body: formData,
    }).then(({ json }) => ({ data: json }));
  },
  createProjectFromForm: (params) => {

    const url = `${authUrl}/register-project`;

    var formData = new FormData();

    for (var key in params) {
      if (typeof (params[key]) !== 'object') {
        formData.append(key, params[key]);
      }
    }

    if (params.no_land_registered_contract_files && params.no_land_registered_contract_files !== undefined) {
      params.no_land_registered_contract_files.forEach(file => {
        if (file && file.rawFile) {
          formData.append('no_land_registered_contract_files', file.rawFile);
        }
      });
    }
    if (params.survey_files && params.survey_files !== undefined) {
      params.survey_files.forEach(file => {
        if (file && file.rawFile) {
          formData.append('survey_files', file.rawFile);
        }
      });
    }
    if (params.survey_dwg_files && params.survey_dwg_files !== undefined) {
      params.survey_dwg_files.forEach(file => {
        if (file && file.rawFile) {
          formData.append('survey_dwg_files', file.rawFile);
        }
      });
    }
    if (params.contract_files && params.contract_files !== undefined) {
      params.contract_files.forEach(file => {
        if (file && file.rawFile) {
          formData.append('contract_files', file.rawFile);
        }
      });
    }

    return httpClient(url, {
      method: 'POST',
      body: formData,
      //body: JSON.stringify(params),
    }).then(({ json }) => ({ data: json }));
  },

  getPreContractForms: () =>
    httpClient(`${apiUrl}/register-project`).then(({ json }) => ({
      data: json,
    })),

  getPreContractFormDetails: (params) =>
    httpClient(`${apiUrl}/register-project/${params.id}`).then(({ json }) => ({
      data: json,
    })),
  inviteClient: (params) =>
    httpClient(`${apiUrl}/invite-client`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({
      data: json
    })),
  getChatRooms: (params) => {
    const query = {
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/clientrooms?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({
      data: json,
    }))
  },

  getChatmessages: (params) => {
    const query = {
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/chat-messages?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({
      data: json,
    }))
  },

  uploadChatFiles: (params) => {

    const url = `https://www.nexaplan.com.au:3000/app/uploadchatfiles`;

    var formData = new FormData();

    if (params.data.files && params.data.files !== undefined) {
      params.data.files.forEach(file => {
        if (file && file.rawFile) {
          formData.append('attachments', file.rawFile);
        }
      });
    }

    return httpClient(url, {
      method: 'POST',
      body: formData,
    }).then(({ json }) => ({ data: json }));
  },
  updateUserPlayerId: (params) => {

    const url = `${apiUrl}/save-playerid`;

    return httpClient(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({ data: json }));
  },

  getProjectCertificates: (resource, params) => {
    const query = {
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`

    return httpClient(url).then(({ headers, json }) => {
      return ({
        data: json,
      })
    });
  },

  createCertificate: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: json,
    }))
  },

  updateCertificates: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },


  uploadCertificates: (params) => {

    const url = `${apiUrl}/certificates-upload`

    var formData = new FormData();


    for (var key in params) {
      if (typeof (params[key]) !== 'object') {
        formData.append(key, params[key]);
      }
    }

    if (params.files && params.files !== undefined) {
      params.files.forEach(file => {
        if (file && file.rawFile) {
          formData.append('certificates', file.rawFile);
        }
      });
    }

    return httpClient(url, {
      method: 'POST',
      body: formData,
    }).then(({ json }) => ({ data: json }));
  },
  getProjectTasksStatus: (params) => {
    const query = {
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/tasks-status?${stringify(query)}`

    return httpClient(url).then(({ headers, json }) => {
      return ({
        data: json,
      })
    });
  },
  removeProjectTask: (params) => {
    return httpClient(`${apiUrl}/remove-projecttask`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: json,
    }))
  },
  getInvitedInfo: (resource, params) =>
    httpClient(`${authUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),
  getInvitedList: (resource) => {
    const url = `${apiUrl}/${resource}`;
    return httpClient(url).then(({ json }) => ({
      data: json,
    }))
  },
  createPopup: (params) => {

    var formData = new FormData();

    for (var key in params) {
      if (typeof (params[key]) !== 'object') {
        formData.append(key, params[key]);
      }
    }
    // formData.append('users', JSON.stringify(params.users));

    if (params.ad_image && params.ad_image !== undefined && params.ad_image.rawFile) {
      formData.append('popupimage', params.ad_image.rawFile);
    }

    return httpClient(`${apiUrl}/app-popups`, {
      method: 'POST',
      body: formData,
    }).then(({ json }) => ({ data: json }));
  },
  projectOnlineStatus: (params) => {
    return httpClient(`${apiUrl}/project-online`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: json,
    }))
  },
  addEmployee: (params) =>
    httpClient(`${apiUrl}/refer-employee`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({
      data: json
    })),

  getEmployees: (params) =>
    httpClient(`${apiUrl}/refer-employees/${params.id}`).then(({ json }) => ({
      data: json
    })),

  sendMessage: (params) =>
    httpClient(`${apiUrl}/send-message`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({
      data: json
    })),

  createGroup: (params) => {
    return httpClient(`${apiUrl}/create-group`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({
      data: json
    }))
  },
  getGroups: () => {
    return httpClient(`${apiUrl}/get-groups`).then(({ json }) => ({
      data: json
    }))
  },
  updateGroup: (params) => {
    return httpClient(`${apiUrl}/update-group`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({
      data: json
    }))
  },
  deleteGroup: (params) => {
    return httpClient(`${apiUrl}/delete-group`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({
      data: json
    }))
  },

  uploadAttachments: (params) => {
    const url = `${apiUrl}/upload-attachments`
    var formData = new FormData();
    for (var key in params) {
      if (typeof (params[key]) !== 'object') {
        formData.append(key, params[key]);
      }
    }

    if (params.files && params.files !== undefined) {
      params.files.forEach(file => {
        if (file && file.rawFile) {
          formData.append('attachments', file.rawFile);
        }
      });
    }
    return httpClient(url, {
      method: 'POST',
      body: formData,
    }).then(({ json }) => ({ data: json }));
  },
  createTender: (params) => {
    return httpClient(`${apiUrl}/create-tender`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({
      data: json
    }))
  },
  getTenders: (params) => {
    const query = { filter: JSON.stringify(params) };
    return httpClient(`${apiUrl}/get-tender?${stringify(query)}`).then(({ json }) => ({
      data: json
    }))
  },
  updateTender: (params) => {
    return httpClient(`${apiUrl}/update-tender`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({
      data: json
    }))
  },
  deleteTender: (params) => {
    return httpClient(`${apiUrl}/delete-tender`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({
      data: json
    }))
  },


  quoteTender: (params) => {
    return httpClient(`${apiUrl}/quote-tender`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({
      data: json
    }))
  },
  getTenderQuote: (params) => {
    return httpClient(`${apiUrl}/get-tender-quote?tender=${params.tender}&quotee=${params.quotee}`).then(({ json }) => ({
      data: json
    }))
  },

  getAllTenderQuotes: (params) => {
    const query = { filter: JSON.stringify(params.filter) };
    return httpClient(`${apiUrl}/getall-tenderquotes?${stringify(query)}`).then(({ json }) => ({
      data: json
    }))
  },
  markQuote: (params) => {
    return httpClient(`${apiUrl}/mark-quote`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({
      data: json
    }))
  },
  markTender: (params) => {
    return httpClient(`${apiUrl}/mark-tender`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({
      data: json
    }))
  },
  getAllProjects: () => {
    return httpClient(`${apiUrl}/getall-projects`).then(({ json }) => ({
      data: json
    }))
  },
  createUnit: (params) => {
    return httpClient(`${apiUrl}/unit`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({
      data: json
    }))
  },
  updateUnit: (params, resource) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params),
    }).then(({ json }) => ({ data: json }));
  },
  createCategory: (params, resource) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({
      data: json
    }))
  },
  updateCategory: (params, resource) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params),
    }).then(({ json }) => ({ data: json }));
  },
  updateEstimate: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params),
    }).then(({ json }) => ({ data: json }));
  },
  deleteEstimate: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
      body: JSON.stringify(params),
    }).then(({ json }) => ({ data: json }));
  },
  getTenderAccess: (params) => {
    return httpClient(`${apiUrl}/get_tender_access/${params.id}`).then(({ json }) => ({
      data: json
    }))
  },
  createChecklist: (params, resource) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({
      data: json
    }))
  },
  updateChecklist: (params, resource) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params),
    }).then(({ json }) => ({ data: json }));
  },
  createSubchecklist: (params, resource) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({
      data: json
    }))
  },
  updateSubchecklist: (params, resource) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params),
    }).then(({ json }) => ({ data: json }));
  },
  createInspection: (params, resource) => {
    console.log(params, resource)
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({
      data: json
    }))
  },
  updateInspection: (params, resource) => {
    console.log(params, resource)
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params),
    }).then(({ json }) => ({ data: json }));
  },
  getQuestionCategories: (resource) => {
    return httpClient(`${apiUrl}/${resource}`).then(({ json }) => ({
      data: json
    }))
  },
  createFloorplan: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({
      data: json
    }))
  },
  updatefloorplan: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params),
    }).then(({ json }) => ({ data: json }));
  },
  getOneFloorPlan: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json
    }))
  },
  getManagers: (resource, params) => {
    const { page, perPage } = params.pagination;
    const order = params.sort.order.toLowerCase();
    const field = (params.sort.field === 'id') ? '_id' : params.sort.field;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}/all?${stringify(query)}`;
    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get('x-total-count'), 10),
    }));
  },
  getContractors: (resource, params) => {
    const { page, perPage } = params.pagination;
    const order = params.sort.order.toLowerCase();
    const field = (params.sort.field === 'id') ? '_id' : params.sort.field;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get('x-total-count'), 10),
    }));
  },
  updateinspectionContractor: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'PUT',
      body: JSON.stringify(params),
    }).then(({ json }) => ({ data: json }));
  },

  // NEW INSPECTION DATA
  getInspectionList: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json
    }))
  },
  getInspectionData: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.type}`).then(({ json }) => ({
      data: json
    }))
  },
};
