import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  required,
  SelectInput
} from 'react-admin';

const useStyles = makeStyles({
  drawer: {
    width: 400,
  },
  heading: {
    padding: '10px 0',
  }
});

export default function AddPermission({toggleDrawer,open,addnewFile,project}) {
  
  const classes = useStyles();
  const save = (data) => {
    addnewFile(data);
  };

  return (
    <div>
      <React.Fragment>
        <Drawer anchor={'right'} open={open} onClose={toggleDrawer(false)}>
          <div className={classes.drawer}>
            <SimpleForm variant="outlined" save={save}>
              <div className={classes.heading}>
                Create Your Certificate Here
              </div>
              <TextInput
                label="Certificate Name"
                fullWidth={true}
                source="name"
                validate={[required()]}
                size="small"
              />
              {/* <RadioButtonGroupInput
                source="assigned_to"
                label="Assign this file to"
                choices={[
                  { id: 'contractor', name: 'Contractors' },
                  { id: 'client', name: 'Client' },
                ]}
                defaultValue={'contractor'}
                disabled={true}
              /> */}
              <ReferenceInput
                reference="projecttask"
                source={'task'}
                label="Task"
                variant="outlined"
                resource="projecttask"
                fullWidth={true}
                validate={required()}
                filter={{
                  project: project,
                  all: true
                }}                  
              >
                <SelectInput optionText="title" />
              </ReferenceInput>
              {/* <FormDataConsumer>
                {({ formData, ...rest }) => {
                  return (
                    formData && formData.assigned_to === 'contractor' && (
                      <ReferenceInput
                        reference="projecttask"
                        source={'task'}
                        label="Task"
                        variant="outlined"
                        resource="projecttask"
                        fullWidth={true}
                        validate={required()}
                        filter={{
                          project: project,
                        }}                     
                      >
                        <SelectInput optionText="title" />

                      </ReferenceInput>
                    )
                  )
                }}
              </FormDataConsumer> */}
             
              {/* <CheckboxGroupInput
                source="added_to"
                row={true}
                label="Assign this file to"
                choices={[
                  { id: 'contractor', name: 'Contractors' },
                  { id: 'client', name: 'Client' },
                ]}
                // validate={[required()]}
              /> */}
            </SimpleForm>
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
