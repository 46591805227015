/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { setSidebarVisibility, useDataProvider, Loading, usePermissions, useAuthProvider, TextField, Datagrid, useRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Paper, Tab, Typography, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import EventEditDialog from './EventDialog';
import FullCalender from './Fullcalender';
import Gantt from '../components/Gantt';
import SortButton from './GhantSortButton';
import TaskSortButton from './GuantTaskSort'
import TaskSearchButton from './GuantTaskSearch'
import ContractorTaskInfo from './Schedule_Modal';
import TaskList from './ListTasks';
import { useHistory } from 'react-router-dom';
import Create from './VariationCreate';
import ProjectTaskList from '../projecttask/ProjectTaskList';
import List from './variationlist';

const queryString = require('query-string');

export default function ProjectTaskChart({ id, match, location, history }) {
  const [project, setProject] = useState(id);
  const routeHistory = useHistory();
  const redirect = useRedirect();
  const parsedQuery = queryString.parse(location.search);
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [data, setdata] = useState();
  const [unit, setUnit] = useState();
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();
  const { permissions } = usePermissions();
  const authProvider = useAuthProvider();
  const user = authProvider.getIdentity();
  const handleDialogOpen = (event, isGhant = false) => {
    if (isGhant) { event['isGhant'] = true }
    setSelectedEvent(event);
    setOpenDialog(true);
  };
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();

  const onDeleteClick = (id) => {
    setLoading(true);
    dataProvider.delete('projectvariation', { id: id, projectId: id })
      .then(({ data }) => {
        dataProvider.getvariations('projectvariation', { filter: { project: project }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
          var totalcost = 0
          data.variation.map((item) => {
            totalcost = totalcost + item.total
          })
          setValue(totalcost)
          setdata(data)
          setLoading(false);

        })
          .catch(error => {
            setLoading(false);
            // console.log(error);
          });

      })
      .catch(error => {
        setLoading(false);
      });
  }
  // const saveProjectMaterial = (data) => {}
  const onEditClick = (materialData) => {
    history.push(`/project-variations/edit/${materialData.id}`);
  }
  const createProjectVariation = (data) => {
    setLoading(true);
    data = { ...data, projectId: id };
    dataProvider.newcreate('projectvariation', { data: data }).then(({ data }) => {
      dataProvider.getvariations('projectvariation', { filter: { project: id }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
        var totalcost = 0
        data.variation.map((item) => {
          totalcost = totalcost + item.total
        })
        setValue(totalcost)
        setdata(data)
        setLoading(false);

      })
        .catch(error => {
          setLoading(false);
          // console.log(error);
        });
    })
      .catch(err => {
        setLoading(false);
      });
  }

  useEffect(() => {
    setdata([]);
    if (!loading) setLoading(true);
    dispatch(setSidebarVisibility(false));
    dataProvider.getvariations('projectvariation', { filter: { project: id }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      var totalcost = 0
      data.variation.map((item) => {
        totalcost = totalcost + item.total
      })
      setValue(totalcost)
      setdata(data)
      setLoading(false);

    })
      .catch(error => {
        setLoading(false);
        // console.log(error);
      });
    // dataProvider.getList('unit', { filter: {}, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
    //   console.log(data)
    //   setUnit(data);
    //   setLoading(false);
    // })
    //   .catch(error => {
    //     setLoading(false);
    //   });
    return () => {
      dispatch(setSidebarVisibility(true));
    };
  }, [id]);

  if (loading || !permissions) return <Loading loadingPrimary="" loadingSecondary="" />;
  if (!data) return null;

  return (
    <>
      {(data && (
        <Paper square className={classes.titlePaper}>
          <Create save={createProjectVariation} />
          <List
            projectId={id}
            data={data}
            value={value}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
          />
        </Paper>
      ))}
    </>
  )
}
const useStyles = makeStyles((theme) => ({
  titlePaper: {
    background: '#f3f3f3',
    marginTop: 20,
    boxShadow: 'none',
  },
  tab: {
    minWidth: 130,
    width: 130,
    background: '#fff',
    fontSize: 13,
    textTransform: 'capitalize',
    border: '0.2px solid #ebebeb',
  },
  selectedTab: {
    background: ' #555cb1',
    color: ' #fff !important',
  },
  tabIndicator: {
    // background:' #555cb1',
    // color:' #fff !important',
    // height: '100%',
  },
}));


